import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
// import QRCodeStyling from "qr-code-styling";
import { useNavigate } from "react-router-dom";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";
import "./QRCode.css";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHeart,
  faMagnifyingGlass,
  faHouse,
  faCircleQuestion,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import QRCode from "react-qr-code";
import Footer from "./Footer";
library.add(faDownload);

function QRCodeGenerator() {
  const [link, setLink] = useState("https://starfruittech.com/");
  const [file, setFile] = useState("png");
  const oopRef = useRef(null);
  const componentRef = useRef(null);
  const [address, setAddress] = useState("");
  const [restaurantID, setRestaurantID] = useState("");
  const [restaurantName, setRestaurantName] = useState("");

  // const link = useRef("https://starfruittech.com/");
  // const file = useRef(null);

  // const qrCode = new QRCodeStyling({
  //   margin: 5,
  //   width: 270,
  //   height: 270,
  //   // image:
  //   //   "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
  //   dotsOptions: {
  //     color: "black",
  //   },
  //   imageOptions: {
  //     crossOrigin: "anonymous",
  //     margin: 5,
  //   },
  // });

  useEffect(() => {
    // qrCode.append(oopRef.current);
    // qrCode.update({
    //   data: "https://starfruittech.com/",
    // });
    // qrCode.append(document.getElementById("canvasss"));
    // qrCode.append(qr);
  }, []);

  // useEffect(() => {
  //   // if (restaurantName !== "Your Restaurant") {
  //   // alert(
  //   //   `https://starfruittech.com/feedback/id=${restaurantID}/name=${restaurantName}`
  //   // );
  //   // alert("hi");
  //   qrCode.update({
  //     data: `https://starfruittech.com/feedback/id=${restaurantID}/name=${restaurantName}`,
  //   });
  //   // qrCode.refresh();
  //   // }
  // }, [restaurantID]);

  const onExtensionChange = (event) => {
    setFile(event.target.value);
  };

  const onDownloadClick = () => {
    if (file === "png") {
      exportComponentAsPNG(componentRef);
    } else if (file === "jpeg") {
      exportComponentAsJPEG(componentRef);
    } else if (file === "pdf") {
      exportComponentAsPDF(componentRef);
    }
  };

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref} className="qrCodeDownloadDivOuter">
      <img className="qrCodeLogo" src={require("./starfruittitle.png")} />
      <div className="qrCodeDownloadDivInner">
        <h3>Give Private Feedback</h3>
        <h1>
          {restaurantName ? restaurantName.toUpperCase() : "Your Restaurant"}
        </h1>
        {/* <div ref={oopRef} /> */}
        <div>
          <QRCode
            value={link}
            imageRendering={require("./starfruitSingleLogo.png")}
            style={{
              height: "auto",
              width: "200px",
              // marginTop: "-3px",
              marginBottom: "-130px",
            }}
            viewBox={`0 0 256 256`}
          />
        </div>
        <img
          // className="qrCodeLogo"
          id="qrCodeSingleLogo"
          src={require("./starfruitSingleLogo.png")}
          // style={{ marginTop: "100px", width: "65px" }}
        />
        <div className="scanDiv">SCAN</div>
      </div>
      <h2>StarfruitTech.com</h2>
    </div>
  ));

  const navigate = useNavigate();

  const searchOptions = {
    types: ["restaurant", "bakery", "bar", "cafe"],
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    var restaurantID = results[0].place_id;
    var restaurantName = value.substr(0, value.indexOf(","));
    setRestaurantName(restaurantName);
    setAddress(restaurantName);
    // setRestaurantID(restaurantID);
    // qrCode.update({
    //   data: `https://starfruittech.com/feedback/id=${restaurantID}/name=${restaurantName}`,
    // });
    // setRestaurantID(restaurantID);
    // setLink(`/feedback/id=${restaurantID}/name=${restaurantName}`);
    setLink(
      `https://starfruittech.com/feedback/id=${restaurantID}/name=${restaurantName}`
    );
    // qrCode.update({
    //   data: `https://starfruittech.com/feedback/id=${restaurantID}/name=${restaurantName}`,
    // });
    // qrCode.update({
    //   data: `https://starfruittech.com/feedback/id=${restaurantID}/name=${restaurantName}`,
    // });
    // console.log(qrCode);
    // console.log(restaurantID);
    // console.log(restaurantName);
    // alert(`https://starfruittech.com/feedback/id=${restaurantID}/name=${restaurantName}`);
  };

  return (
    <div className="qrCodeWhole">
      <Navbar />
      <div className="qrCodeWholeInner">
        <div className="qrCodeOptions">
          {/* <input value={url} onChange={onUrlChange} /> */}
          {/* <input placeholder="Restaurant Name" onChange={onUrlChange} /> */}
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
            searchOptions={searchOptions}
            className="placesAutocomplete"
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="qrCodeOptionsPlaces">
                <FontAwesomeIcon
                  id="qrCodeInputIcon"
                  icon="fa-solid fa-magnifying-glass"
                />
                <input
                  {...getInputProps({ placeholder: "Find Restaurants" })}
                />

                {/* div for all the suggestions */}
                <div className="qrCodeRestaurantSuggestionsDiv">
                  {loading ? <div>...loading</div> : null}

                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active
                        ? "rgba(256,212,36, 1)"
                        : "#fff",
                      fontSize: "15px",
                      paddingBottom: "8px",
                      paddingTop: "8px",
                      marginLeft: "-7px",
                      marginRight: "-7px",
                      marginTop: "4px",
                      marginBottom: "4px",
                    };

                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {/* <select onChange={onExtensionChange} value={fileExt}> */}

          {/* <select onChange={onExtensionChange}>
            <option value="png">PNG</option>
            <option value="jpeg">JPEG</option>

          </select> */}

          <button id="downloadQRButtonLarge" onClick={onDownloadClick}>
            <FontAwesomeIcon
              icon="fa-solid fa-download"
              style={{ marginRight: "7px" }}
            />
            Download
          </button>
          <button id="downloadQRButtonSmall" onClick={onDownloadClick}>
            <FontAwesomeIcon icon="fa-solid fa-download" />
          </button>
          {/* <button>Go</button> */}
        </div>
        <ComponentToPrint ref={componentRef} />
        {/* <div id="canvasss"></div> */}

        {/* <div className="qrCodeDownloadDivOuter">
        <img className="qrCodeLogo" src={require("./starfruittitle.png")} />
        <div className="qrCodeDownloadDivInner">
          <h3>Give Private Feedback</h3>
          <h1>
            {restaurantName ? restaurantName.toUpperCase() : "Your Restaurant"}
          </h1>
          <QRCode value={link} />
          <div className="scanDiv">SCAN</div>
        </div>
        <h2>StarfruitTech.com</h2>
      </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default QRCodeGenerator;
