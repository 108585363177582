import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Input } from "antd";
import { FaCaretDown } from "react-icons/fa";
import Ratings from "react-ratings-declarative";
import Faq from "react-faq-component";
import { auth, provider, db } from "./firebase-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { signInWithPopup, getAuth, onAuthStateChanged } from "firebase/auth";
//https://www.npmjs.com/package/react-faq-component
import "./Business.css";
import { useNavigate } from "react-router-dom";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  addDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faQuoteLeft,
  faQuoteRight,
  faStar,
  faBullhorn,
  faQrcode,
  faChartLine,
  faFileCircleCheck,
  faFileContract,
  faFileClipboard,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";

import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import BusinessAnalytics from "./BusinessAnalytics";
import BusinessVerificationNew from "./BusinessVerificationNew";

library.add(
  faQuoteLeft,
  faQuoteRight,
  faStar,
  faBullhorn,
  faQrcode,
  faChartLine,
  faFileCircleCheck,
  faFileContract,
  faFileClipboard,
  faArrowAltCircleRight
);

function Business({ isAuth, setIsAuth }) {
  // const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
  const navigate = useNavigate();

  const [hello, setHello] = useState("");

  const [user, setUser] = useState(null);
  const [userVerified, setUserVerified] = useState([]);

  const [clickedIndexDemo, setClickedIndexDemo] = useState();

  const [individualReviewSearchInputDemo, setIndividualReviewSearchInputDemo] =
    useState("");

  const [hi, setHi] = useState("");

  const [writtenNotesFirstDemo, setWrittenNotesFirstDemo] = useState(
    "We had a lovely visit at your restaurant. The staff truly cared about our experience, and everything we tasted melted in our mouths. My only note would be the cleanliness of your bathrooms when we visited for dinner, but otherwise, a wonderful experience. Thank You!"
  );
  const [writtenNotesSecondDemo, setWrittenNotesSecondDemo] = useState(
    "I enjoyed every single bite of your signature burger. The price was very reasonable and the atmosphere was perfect as a date night location for me and my wife. I did notice some staff were on their phones and slowed down service but otherwise, I would highly recommend your establishment to my friends."
  );

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      console.log("authchanbge");
    });
  }, []);

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider).then((result) => {
      localStorage.setItem("isAuth", true);
      setIsAuth(true);
    });
  };

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  useEffect(() => {
    // console.log(userVerified);
  }, [userVerified]);

  useEffect(() => {
    if (user) {
      const qqqq = query(
        collection(db, "business owners"),
        where("userUID", "==", user.uid)
      );
      const getUserVerified = async () => {
        const data = await getDocs(qqqq);
        setUserVerified(
          data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );

        // console.log(data);

        if (data.empty) {
          // alert("user not in database");
          const docRef = await addDoc(collection(db, "business owners"), {
            userUID: user.uid,
            business_account_date_created: Timestamp.now(),
            business_account_verification_status: "not yet reviewed",
            restaurant_name: "",
            restaurant_google_places_ID: "",
            owner_first_name: "",
            owner_last_name: "",
            business_account_date_verified: "",
            business_account_use_reason: "",
            business_account_where_heard: "",
            business_account_phone_number: "",
            //             "restaurant_name = """"
            // restaurant_google_places_ID = """"
            // owner_first_name = """"
            // owner_last_name = """"
            // business_account_verification_status = ""not yet reviewed""
            // business_account_date_created = Timestamp()
            // business_account_date_verified = """"
            // business_account_use_reason = """"
            // business_account_where_heard = """"
            // business_account_phone_number = """""
          });

          // console.log("Document written with ID: ", docRef.id);
          // console.log(`/businessverificationid=${user.uid}`);
          // navigate(`/businessverificationid=${user.uid}`);
          window.location.reload();
        } else {
          // navigate("/businessanalytics");
          // alert("user in database");
          // const docReff = await getDoc(collection(db, "business owners"), {
          //   userUID: user.uid,
          //   business_account_date_created: "date",
          //   business_account_verification_status: "not yet reviewed",
          // });
          // const qq = query(
          //   collection(db, "business owners"),
          //   where("userUID", "==", user.uid)
          // );
          // const getThing = async () => {
          //   const data = await getDocs(qq);
          //   setReviewsData(
          //     data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          //   );
          // };
          // getThing();
          // if(user){

          // }\
          // alert("hi");
          // console.log(data);
          if (
            data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0]
              .business_account_verification_status == "not yet reviewed"
          ) {
            navigate(`/businessverification/id=${user.uid}`);
          } else {
            navigate(`/businessanalytics/id=${user.uid}`);
          }
        }
      };
      getUserVerified();
    }
  }, [user]);

  const data = {
    title: " ",
    rows: [
      {
        title: "What is Starfruit's mission?",
        // ⛳️
        // content: `Starfruit's mission is to help local businesses stay on top of evolving customer demands, and retain lifelong customer relationships. We do this by providing effective, private, feedback technology, to bridge the communication and knowledge gap between: A) "What Owners Believe Customers Want" and B) "What Customers Actually Want."`,
        // content: `Help local businesses make data-driven decisions, and bridge the knowledge gap between what owners believe customers want and what customers actually want.`,
        content: `We help local businesses learn what their customers want and make data-driven decisions.`,
      },
      {
        title: "How do we accomplish our mission?",
        //✅
        // content: `We accomplish our mission by making feedback quick and easy for customers to provide, supplying businesses with in-store QR codes, and allowing visitors of our homepage to search and submit feedback to any restaurant at their own leisure. Owners may then visit their Starfruit “Business Dashboard” to view all their raw customer data and computed metrics to make decisions best for their companies.`,
        // content: `We make customer feedback easy to submit—through our homepage searchbar and physical QR codes—so that businesses gain abundant and valuable data. Owners may then view their “Business Dashboard” to utilize raw feedback and computed metrics in their decision making.`,
        // content: `We make customer feedback easy to collect. Our experience processing thousands of submissions has allowed us to scientifically design the Starfruit Survey™ for user-friendliness and efficacy. We provide businesses with:

        // 1.	Their own Starfruit survey,
        // 2.	Printable QR codes for customer scanning, and
        // 3.	a dashboard where business owners can view submitted feedback.
        // Ready to start collecting feedback through Starfruit? Click "Login" abve!`,
        content: `We make customer feedback easy to collect. Our experience processing thousands of submissions has allowed us to scientifically design the Starfruit Survey™ for user-friendliness and efficacy. We provide businesses with: 1) their own Starfruit survey, 2) Printable QR codes for easy customer scanning, and 3) a dashboard for business owners to view submitted feedback. Click "Login" above to start collecting feedback with Starfruit!`,
      },
      {
        title: "Why should I utilize private feedback?",
        //📝
        content:
          // "While public reviews are essential in drawing new customers, we believe private feedback, without customer login or online viewership, increases volume of information shared, and allows customers to be honest without public pressure. As we know, assumptions on data are only noteworthy if the data is abundant and representative — which private feedback alone has the option to accomplish. This trend towards private feedback is even notable in internet savvy restaurants such as Din Tai Fung and Kura Revolving Sushi who all utilize private feedback technology to increase data for sound decision making.",
          // "While public review sites are essential for businesses
          // to gain new customers, Starfruit's goal is to retain each
          // customer that walks through your doors. Our plaform gives
          // patrons the flexibility to leave feedback whenebver and
          // Our private feedback technology allows customers to provide honest information at their leisure, whenever supporting businesses without the added pressure knowing their their feedback could be interpreted in whatever way by eyes across the internet
          // whereever, increasing valuable constructive feedback and information your own customer background
          // without the eyes of anyone that stubles across your business online. We belive this private feedback will not only increase your business the insightful edge, but not deter from the positive elements on your public pages, as we affirm",
          // As you may have heard, `Acquiring a new customer can cost five times more than retaining an existing customer.` While public reviews are indeed valuable, Starfruits platform
          // s for each customer that walks through their doors

          // Data also shows that in the US, it is the same 5% - 10% minority of restaurant goers that ever post on public review sites such as Yelp., Starfruit allows business owners to access the other 90% - 95%, who are willing to give restaurant owners helpful feedback, but would never leave a public review due to a variety of reasons such as privacy concerns, accessibility, or lack of motivation.
          // "While public reviews showcase your business to new customers, private feedback can be essential in retaining each customer that walks through your doors. Without user login to submit feedback, more customers can leave praise and suggestions at their leisure. This trend towards private feedback is even notable in internet savvy restaurants such as Din Tai Fung and Kura Revolving Sushi who all utilize private feedback technology to keep patrons happy and eager to return.",
          "While public reviews showcase your business to new customers, private feedback can be essential in retaining each customer that walks through your doors. Without user login, all customers can leave praise and suggestions at their leisure. This trend towards private feedback is even notable in internet savvy restaurants such as Din Tai Fung and Kura Revolving Sushi who all utilize private feedback to keep patrons happy and eager to return.",
      },
      {
        title:
          "Will utilizing private feedback reduce customers who post positive public feedback?",
        //⭐️
        content:
          // "Therefore, we are reaching out to a different and larger section of customer feedback.
          // "We believe not. Each time our feedback form is visited, we make viewers agree that any information provided is viewable only by the business owner. The disclaimer also shares links to Yelp, Google Reviews, and Facebook should the visitor decide to leave a public review instead. In fact, data shows that in the US, only 5% - 10% of restaurant goers ever post on public review sites. Starfruit seeks access the other 90% - 95% who are willing to provide helpful feedback, but never leave a public review due to privacy concerns, accessibility, or lack of motivation.",
          "We believe not. Each time our feedback form is visited, we make viewers agree that any information provided is viewable only by the business owner. The disclaimer also shares links to Yelp, Google Reviews, and Facebook should the visitor decide to leave a public review instead. In fact, data shows only 5% - 10% of restaurant goers ever post on public review sites. Starfruit seeks to access the other 90% who are willing to provide helpful feedback, but never leave a public review due to privacy concerns, accessibility, or lack of motivation.",
      },
      {
        title: "How do I access my customer feedback?",
        //📊
        // content: `Make a Starfruit business account, which you can do by clicking "Login" above and choosing a Google Account to continue with. After a 5 minute "Business Verification Form," you will be able to view your data on your restaurant's "Business Dashboard!”`,
        content: `Make a Starfruit business account, which you can do by clicking "Login" above and choosing a Google account to continue with. After a 5 minute verification form you will be able to view your data!`,
      },
      // {
      //   title: "Who has access to my business' data? 🔍",
      //   content: "What does private mean, 🕵️‍♀️ and w",
      // },
      {
        title: "How do I encourage customers to leave reviews?",
        //🗣
        // content:"Encourage more reviews by displaying your Starfruit QR Code in visible areas such as at point-of-sale, on tables, doors, or windows. You can display as many or as little QR codes as your want, wherever you want.",
        content:
          "Encourage more reviews by displaying your Starfruit QR Code in visible areas such as point-of-sale, on tables, doors, or windows. You can display as many or as few QR codes as you want, wherever you want.",
      },
      {
        title: "Where can I get the QR code for my specific feedback form?",
        //📲
        // content: `Grab your QR Code with this link, or by clicking the "Get QR Code" button above and typing in your restaurant name. After the QR code updates, click the white download button to save the png file to your device.`,
        content: `Grab your QR Code by clicking the "Get QR Code" button above and typing in your restaurant name. After the QR updates, click the white download button to save the png file.`,
      },
      {
        title: "Is Starfruit free for use?",
        //🧐
        content:
          // "Yes! Creating a business account on Starfruit is entirely free. We do not charge businesses to view their own customer data, but make money through Non-competitve Google ads on our feedback forms when customers leave a review.",
          // With our service, you can view raw customer feedback, important statistics, and valuable data visualizations!
          "Yes! Creating a business account on Starfruit is entirely free. We do not charge businesses to view their own customer data, but make money through Non-competitive Google ads on our feedback forms.",
      },
    ],
  };

  const styles = {
    bgColor: "none",
    titleTextColor: "white",
    titleTextSize: "24px",

    rowTitleColor: "black",
    rowTitleTextSize: "16px",

    rowContentTextSize: "16px",
    // rowContentColor: "#ac843c",
    rowContentColor: "rgb(180, 180, 180)",
  };

  const config = {};

  return (
    <div>
      <Navbar />
      <div className="businessPageBody">
        <div className="homepageBodyFirstBackground"></div>
        {/* <div className="starfruitBusinesspageFirstBackground"></div> */}
        <div className="starfruitBusinesspageFirstBackgroundDiv">
          <img
            className="starfruitBusinesspageFirstBackground"
            src={require("./starfruitBusinesspageFirstBackground.png")}
          ></img>
        </div>
        <div className="businessIntro">
          <h1 className="businessIntroFirstTitle">Business Owner?</h1>
          <p>Start by Logging In with your Google Account!</p>
          <button
            id="loginButton"
            className="login-with-google-btn"
            onClick={signInWithGoogle}
          >
            Login
          </button>
          <button
            id="getQRCodeButton"
            className="login-with-google-btn"
            onClick={() => navigate("/qrcode")}
          >
            Get QR Code
          </button>
          <div>
            <img
              className="starfruitBusinesspageFirstInfographic"
              src={require("./starfruitBusinesspageFirstInfographic.png")}
            ></img>
          </div>
        </div>

        <div
          className="businesspageBodyThirdBackground businesspageBodySeventhBackground"
          style={{ paddingBottom: "20px" }}
        >
          <div className="faqDivOuter">
            <div className="faqDivInner">
              <h1>Starfruit's Mission</h1>
              <div
                style={{
                  // background: "white",
                  // padding: "17px",
                  // borderRadius: "5px",
                  marginTop: "25px",
                  // marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    // color: "rgb(169 132 0)",
                    fontWeight: "500",
                    fontSize: "17px",
                    lineHeight: "1.2",
                    marginTop: "0px",
                    marginBottom: "20px",
                    // color: "rgb(50, 50, 50)",
                    color: "rgb(169 132 0)",
                  }}
                >
                  {/* Starfruit's mission is to help local businesses stay on top of
                  evolving customer demands, and retain lifelong customer
                  relationships. */}
                  {/* Help local businesses make data-driven decisions, and bridge
                  the knowledge gap between what owners believe and what
                  customers want. */}
                  We help local businesses learn what their customers want.
                </p>
                {/* <p>How do we accomplish this mission?</p> */}
                <div className="missionElementOuterDiv">
                  <div className="missionElementInnerDiv">
                    <div
                      className="missionElement"
                      style={{
                        backgroundColor: "white",
                        padding: "17px",
                        borderRadius: "5px",
                        marginTop: "20px",
                        // width: "25%",
                        // marginRight: "7px",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                        }}
                      >
                        <h3>1.</h3>
                        <h2>
                          Private Feedback
                          {/* <FontAwesomeIcon
                            className="missionIcon"
                            style={{
                              marginLeft: "8px",
                              fontSize: "16px",
                              color: "rgb(50, 50, 50)",
                            }}
                            icon="fa-solid fa-bullhorn"
                          /> */}
                        </h2>
                      </span>
                      <div className="individualReviewNotesDivDemo">
                        <p
                          style={{
                            marginTop: "3px",
                            marginBottom: "3px",
                          }}
                        >
                          Allows the 90% of US customers who never post public
                          reviews the opportunity to provide unique insights.
                        </p>
                      </div>
                      {/* <img
                      style={{ width: "100%" }}
                      src={require("./privateFeedbackScreenshot.png")}
                    /> */}
                    </div>
                    <div
                      className="missionElement"
                      style={{
                        backgroundColor: "white",
                        padding: "17px",
                        borderRadius: "5px",
                        marginTop: "20px",
                        // width: "25%",
                        // marginLeft: "7px",
                        // marginRight: "7px",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                        }}
                      >
                        <h3>2.</h3>
                        <h2>
                          Qr Codes
                          {/* <FontAwesomeIcon
                            className="missionIcon"
                            style={{
                              marginLeft: "8px",
                              fontSize: "17px",
                              color: "rgb(50, 50, 50)",
                            }}
                            icon="fa-solid fa-qrcode"
                          /> */}
                        </h2>
                        <button
                          // id="getQRCodeButton"
                          onClick={() => navigate("/qrcode")}
                          style={{
                            height: "32px",
                            backgroundColor: "rgb(255, 213, 0)",
                            color: "white",
                            width: "110px",
                            border: "none",
                            borderRadius: "3px",
                            marginTop: "-5px",
                            marginLeft: "12px",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Get QR Code
                        </button>
                      </span>
                      <div className="individualReviewNotesDivDemo">
                        <p
                          style={{
                            marginTop: "3px",
                            marginBottom: "3px",
                          }}
                        >
                          Leads straight to your feedback form. Perfect on
                          dining tables, windows, or at point of sale.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="missionElementInnerDiv"
                    style={
                      {
                        // backgroundColor: "pink",
                        // display: "inline",
                      }
                    }
                  >
                    <div
                      className="missionElement"
                      style={{
                        backgroundColor: "white",
                        padding: "17px",
                        borderRadius: "5px",
                        marginTop: "20px",
                        // width: "25%",
                        // marginLeft: "7px",
                        // marginRight: "7px",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          // height: "35px",
                        }}
                      >
                        <h3>3.</h3>
                        <h2>
                          Data Analysis
                          {/* <FontAwesomeIcon
                            className="missionIcon"
                            style={{
                              marginLeft: "8px",
                              fontSize: "15px",
                              color: "rgb(50, 50, 50)",
                            }}
                            icon="fa-solid fa-chart-line"
                          /> */}
                        </h2>
                        <button
                          // id="loginButton"
                          style={{
                            height: "32px",
                            backgroundColor: "white",
                            color: "black",
                            width: "135px",
                            border: "1px solid rgb(240, 240, 240)",
                            borderRadius: "3px",
                            marginTop: "-5px",
                            marginLeft: "12px",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                          className="login-with-google-btn"
                          onClick={signInWithGoogle}
                        >
                          View Dashboard
                        </button>
                      </span>
                      <div className="individualReviewNotesDivDemo">
                        <p
                          style={{
                            marginTop: "3px",
                            marginBottom: "3px",
                          }}
                        >
                          Utilize raw data or metrics on food, service, setting,
                          and value on your dashboard.
                        </p>
                      </div>
                    </div>
                    <div
                      className="missionElement"
                      style={{
                        backgroundColor: "white",
                        padding: "17px",
                        borderRadius: "5px",
                        marginTop: "20px",
                        // width: "25%",
                        // marginLeft: "7px",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          // height: "35px",
                        }}
                      >
                        <h3>4.</h3>
                        <h2>
                          Bi-Weekly Reports
                          {/* <FontAwesomeIcon
                            className="missionIcon"
                            style={{
                              marginLeft: "8px",
                              fontSize: "17px",
                              color: "rgb(50, 50, 50)",
                            }}
                            icon="fa-solid fa-file-contract"
                          /> */}
                        </h2>
                        <a
                          // id="getQRCodeButton"
                          // onClick={() => navigate("/qrcode")}
                          href="mailto:info@starfruittech.com"
                          style={{
                            height: "18px",
                            backgroundColor: "rgb(255, 213, 0)",
                            color: "white",
                            width: "80px",
                            border: "none",
                            borderRadius: "3px",
                            marginTop: "-5px",
                            marginLeft: "12px",
                            fontWeight: "700",
                            fontSize: "14px",
                            textDecoration: "none",
                            textAlign: "center",
                            padding: "7px",
                          }}
                        >
                          Request
                        </a>
                      </span>
                      <div className="individualReviewNotesDivDemo">
                        <p
                          style={{
                            marginTop: "3px",
                            marginBottom: "3px",
                          }}
                        >
                          Upon request, bi-weekly reports from our analysts
                          summarize trends and provide suggestions to ease your
                          decision making.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <p
                style={{
                  // color: "rgb(169 132 0)",
                  fontWeight: "500",
                  fontSize: "17px",
                  marginBottom: "0px",
                  marginTop: "25px",
                  color: "rgb(169 132 0)",
                }}
              >
                We bridge the knowledge gap between:
              </p> */}
                {/* <div style={{}}>
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                    maxWidth: "800px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "max-content",
                        margin: "auto",
                        padding: "12px",
                        borderRadius: "5px",
                        marginTop: "20px",
                        marginRight: "6px",
                        backgroundColor: "white",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginTop: "0px",
                        }}
                        className="individualReviewNotesDivDemo"
                      >
                        <h3>A.</h3>
                        <p
                          style={{
                            marginLeft: "7px",
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                        >
                          "What Owners Believe Customers Want"
                        </p>
                      </div>
                    </div>
                  </div>
                  <FontAwesomeIcon
                    style={{
                      marginLeft: "8px",
                      fontSize: "40px",
                      color: "white",
                    }}
                    icon="fa-solid fa-arrow-alt-circle-right"
                  />
                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "max-content",
                        margin: "auto",
                        padding: "12px",
                        borderRadius: "5px",
                        marginTop: "20px",
                        marginLeft: "6px",
                        backgroundColor: "white",
                      }}
                    >
                      <div
                        style={{ marginTop: "0px", display: "flex" }}
                        className="individualReviewNotesDivDemo"
                      >
                        <h3>B.</h3>
                        <p
                          style={{
                            marginLeft: "7px",
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                        >
                          "What Customers Actually Want"
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div
                  style={{
                    width: "60%",
                    margin: "auto",
                    textAlign: "center",
                    minWidth: "200px",
                    maxWidth: "450px",
                    marginTop: "10px",
                  }}
                >
                  <img
                    style={{
                      width: "60%",
                      minWidth: "200px",
                      maxWidth: "450px",
                    }}
                    src={require("./paperAirplaneNewNewNew.png")}
                  />
                </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="businesspageBodyThirdBackground businesspageBodySeventhBackground">
          <div className="faqDivOuter">
            <div className="faqDivInner">
              {/* <h1 style={{ fontSize: "24px" }}>
                How do we accomplish this mission?
              </h1> */}
              {/* <p>How do we accomplish this mission?</p>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "17px",
                  borderRadius: "5px",
                  marginTop: "25px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                  }}
                >

                  <h3>1.</h3>
                  <h2>
                    Private Feedback
                    <FontAwesomeIcon
                      style={{ marginLeft: "8px", fontSize: "16px" }}
                      icon="fa-solid fa-bullhorn"
                    />
                  </h2>
                </span>
                <div className="individualReviewNotesDivDemo">
                  <p
                    style={{
                      marginTop: "3px",
                      marginBottom: "3px",
                      // color: "rgb(75, 75, 75)",
                      // color: "rgb(169 132 0)",
                      // fontWeight: "500",
                      // fontSize: "17px",

                      // fontSize: "17px",
                      // color: "rgb(180, 180, 180)",
                    }}
                  >
                    hello hello hello hello hello hello hello hello hello hello
                    hello hello hello hello hello hello hello hello hello hello
                  </p>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "17px",
                  borderRadius: "5px",
                  marginTop: "25px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    // height: "35px",
                  }}
                >
                  <h3>2.</h3>
                  <h2>
                    Qr Codes
                    <FontAwesomeIcon
                      style={{ marginLeft: "8px", fontSize: "17px" }}
                      icon="fa-solid fa-qrcode"
                    />
                  </h2>
                </span>
                <div className="individualReviewNotesDivDemo">
                  <p
                    style={{
                      marginTop: "3px",
                      marginBottom: "3px",
                    }}
                  >
                    hello hello hello hello hello hello hello hello hello hello
                    hello hello hello hello hello hello hello hello hello hello
                  </p>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "17px",
                  borderRadius: "5px",
                  marginTop: "25px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    // height: "35px",
                  }}
                >
                  <h3>3.</h3>
                  <h2>
                    Data Analysis
                    <FontAwesomeIcon
                      style={{ marginLeft: "8px", fontSize: "15px" }}
                      icon="fa-solid fa-chart-line"
                    />
                  </h2>
                </span>
                <div className="individualReviewNotesDivDemo">
                  <p
                    style={{
                      marginTop: "3px",
                      marginBottom: "3px",
                    }}
                  >
                    hello hello hello hello hello hello hello hello hello hello
                    hello hello hello hello hello hello hello hello hello hello
                  </p>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "17px",
                  borderRadius: "5px",
                  marginTop: "25px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    // height: "35px",
                  }}
                >
                  <h3>4.</h3>
                  <h2>
                    Bi-Weekly Reports
                    <FontAwesomeIcon
                      style={{ marginLeft: "8px", fontSize: "17px" }}
                      icon="fa-solid fa-file-contract"
                    />
                  </h2>
                </span>
                <div className="individualReviewNotesDivDemo">
                  <p
                    style={{
                      marginTop: "3px",
                      marginBottom: "3px",
                    }}
                  >
                    hello hello hello hello hello hello hello hello hello hello
                    hello hello hello hello hello hello hello hello hello hello
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="businesspageBodyFourthBackground">
          <div className="analyticsDemoOuter">
            <div className="analyticsDemoInner">
              <h1>Demo Dashboard</h1>
              <p
                style={{
                  fontSize: "17px",
                  // paddingBottom: "10px",
                  color: "rgb(180, 180, 180)",
                }}
              >
                Below is an abbreviated business dashboard.
              </p>
              <h2>Summary</h2>
              <div className="summaryDiv" style={{ marginBottom: "30px" }}>
                <div
                  style={{ marginBottom: "15px", padding: "9px" }}
                  className="summaryDivFirstRow"
                >
                  <div>
                    <div className="summaryDivFirstRowInner">
                      <p style={{ fontSize: "12.5px" }}>OVERALL: </p>
                      <h3>{4.5}</h3>
                      <div style={{ marginLeft: "0px", marginTop: "4px" }}>
                        <Ratings
                          rating={4.5}
                          widgetRatedColors="rgb(255, 213, 0)"
                          widgetSpacings="2.2px"
                          widgetHoverColors="#fbc70c"
                          widgetDimensions="24.2px"
                          widgetEmptyColors="rgb(241, 241, 244)"
                        >
                          <Ratings.Widget />
                          <Ratings.Widget />
                          <Ratings.Widget />
                          <Ratings.Widget />
                          <Ratings.Widget />
                        </Ratings>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="summaryDivSecondRow">
                  <div
                    className="summaryDivSecondRowItem"
                    style={{ marginRight: "9px", height: "55px" }}
                  >
                    <p style={{ fontSize: "12.5px", paddingTop: "10px" }}>
                      FOOD:{" "}
                    </p>
                    <h3 style={{ marginTop: "3px" }}>{4.3}</h3>
                  </div>
                  <div
                    className="summaryDivSecondRowItem"
                    style={{ marginRight: "9px", height: "55px" }}
                  >
                    <p style={{ fontSize: "12.5px", paddingTop: "10px" }}>
                      SERVICE:
                    </p>
                    <h3 style={{ marginTop: "3px" }}>{4.6}</h3>
                  </div>
                  <div
                    className="summaryDivSecondRowItem"
                    style={{ marginRight: "9px", height: "55px" }}
                  >
                    <p style={{ fontSize: "12.5px", paddingTop: "10px" }}>
                      SETTING:
                    </p>
                    <h3 style={{ marginTop: "3px" }}>{4.2}</h3>
                  </div>
                  <div
                    style={{ height: "55px" }}
                    className="summaryDivSecondRowItem"
                  >
                    <p style={{ fontSize: "12.5px", paddingTop: "10px" }}>
                      VALUE:
                    </p>
                    <h3 style={{ marginTop: "3px" }}>{4.4}</h3>
                  </div>
                </div>
              </div>
              <h2>Individual Reviews</h2>
              <div
                style={{ marginBottom: "30px" }}
                className="individualReviews"
              >
                <Input
                  style={{ height: "43px" }}
                  className="dashboardInputDemo"
                  onChange={(e) =>
                    setIndividualReviewSearchInputDemo(
                      e.target.value.toLowerCase()
                    )
                  }
                  placeholder="Search Through Written Reviews"
                  prefix={
                    <FontAwesomeIcon
                      id="businessAnalyticsInputIcon"
                      icon="fa-solid fa-magnifying-glass"
                    />
                  }
                />

                {/* if (
                      individualReviewSearchInput === "" ||
                      review.review_written_notes.includes(
                        individualReviewSearchInput
                      )
                    )  */}

                <div className="individualReviewsDivDemo">
                  {individualReviewSearchInputDemo === "" ||
                  writtenNotesFirstDemo
                    .toLowerCase()
                    .includes(individualReviewSearchInputDemo) ? (
                    <div
                      style={{ marginBottom: "21px" }}
                      key={2}
                      className="individualReviewDivDemo"
                    >
                      <div className="individualReviewDateDivDemo">
                        <img
                          src={require("./starfruitSingleLogo.png")}
                          style={{ width: "25px", marginTop: "3px" }}
                        />
                        <p
                          style={{
                            marginTop: "0px",
                            fontSize: "16px",
                            marginLeft: "9px",
                          }}
                          className="reviewDateDemo"
                        >
                          Dec 10 2022
                        </p>
                      </div>
                      <div className="individualReviewRatingDivDemo">
                        <div
                          className="summaryDivDemo"
                          style={{ marginTop: "-3px" }}
                        >
                          <div>
                            <p
                              style={{
                                marginRight: "8px",
                                fontWeight: "500",
                                fontSize: "12.5px",
                              }}
                            >
                              OVERALL:{" "}
                            </p>

                            <Ratings
                              rating={5}
                              widgetRatedColors="rgb(255, 213, 0)"
                              widgetSpacings="2px"
                              widgetHoverColors="#fbc70c"
                              widgetDimensions="22px"
                              widgetEmptyColors="rgb(241, 241, 244)"
                            >
                              <Ratings.Widget />
                              <Ratings.Widget />
                              <Ratings.Widget />
                              <Ratings.Widget />
                              <Ratings.Widget />
                            </Ratings>
                            <button
                              className="individualReviewMoreLargeButtonDemo"
                              onClick={() => {
                                clickedIndexDemo == 2
                                  ? setClickedIndexDemo(null)
                                  : setClickedIndexDemo(2);
                              }}
                            >
                              More Stats
                            </button>
                            <button
                              className="individualReviewMoreSmallButtonDemo"
                              onClick={() => {
                                clickedIndexDemo == 2
                                  ? setClickedIndexDemo(null)
                                  : setClickedIndexDemo(2);
                              }}
                            >
                              <FontAwesomeIcon
                                className="icon"
                                icon="fa-solid fa-arrow-down"
                              />
                            </button>
                          </div>
                          {clickedIndexDemo == 2 && (
                            <div className="individualReviewRatingDivDivDemo">
                              <div
                                className="individualReviewRatingDivItemDemo"
                                style={{ marginRight: "9px" }}
                              >
                                <p>FOOD: </p>
                                <h3>{5}</h3>
                              </div>
                              <div
                                className="individualReviewRatingDivItemDemo"
                                style={{ marginRight: "9px" }}
                              >
                                <p>SERVICE:</p>
                                <h3>{5}</h3>
                              </div>
                              <div
                                className="individualReviewRatingDivItemDemo"
                                style={{ marginRight: "9px" }}
                              >
                                <p>SETTING:</p>
                                <h3>{4}</h3>
                              </div>
                              <div className="individualReviewRatingDivItemDemo">
                                <p>VALUE:</p>
                                <h3>{5}</h3>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="individualReviewNotesDivDemo">
                        <p>{writtenNotesFirstDemo}</p>
                      </div>{" "}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {individualReviewSearchInputDemo === "" ||
                  writtenNotesSecondDemo
                    .toLowerCase()
                    .includes(individualReviewSearchInputDemo) ? (
                    <div key={1} className="individualReviewDivDemo">
                      <div className="individualReviewDateDivDemo">
                        <img
                          src={require("./starfruitSingleLogo.png")}
                          style={{ width: "25px", marginTop: "3px" }}
                        />
                        <p
                          style={{
                            marginTop: "0px",
                            fontSize: "16px",
                            marginLeft: "9px",
                          }}
                          className="reviewDateDemo"
                        >
                          Dec 09 2022
                        </p>
                      </div>
                      <div className="individualReviewRatingDivDemo">
                        <div
                          className="summaryDivDemo"
                          style={{ paddingTop: "3px" }}
                        >
                          <div>
                            <p
                              style={{ marginRight: "8px", fontWeight: "500" }}
                            >
                              OVERALL:{" "}
                            </p>

                            <Ratings
                              rating={4}
                              widgetRatedColors="rgb(255, 213, 0)"
                              widgetSpacings="2px"
                              widgetHoverColors="#fbc70c"
                              widgetDimensions="22px"
                              widgetEmptyColors="rgb(241, 241, 244)"
                            >
                              <Ratings.Widget />
                              <Ratings.Widget />
                              <Ratings.Widget />
                              <Ratings.Widget />
                              <Ratings.Widget />
                            </Ratings>
                            <button
                              className="individualReviewMoreLargeButtonDemo"
                              onClick={() => {
                                clickedIndexDemo == 1
                                  ? setClickedIndexDemo(null)
                                  : setClickedIndexDemo(1);
                              }}
                            >
                              More Stats
                            </button>
                            <button
                              className="individualReviewMoreSmallButtonDemo"
                              onClick={() => {
                                clickedIndexDemo == 1
                                  ? setClickedIndexDemo(null)
                                  : setClickedIndexDemo(1);
                              }}
                            >
                              <FontAwesomeIcon
                                className="icon"
                                icon="fa-solid fa-arrow-down"
                              />
                            </button>
                          </div>
                          {clickedIndexDemo == 1 && (
                            <div className="individualReviewRatingDivDivDemo">
                              <div
                                className="individualReviewRatingDivItemDemo"
                                style={{ marginRight: "9px" }}
                              >
                                <p>FOOD: </p>
                                <h3>{5}</h3>
                              </div>
                              <div
                                className="individualReviewRatingDivItemDemo"
                                style={{ marginRight: "9px" }}
                              >
                                <p>SERVICE:</p>
                                <h3>{4}</h3>
                              </div>
                              <div
                                className="individualReviewRatingDivItemDemo"
                                style={{ marginRight: "9px" }}
                              >
                                <p>SETTING:</p>
                                <h3>{5}</h3>
                              </div>
                              <div className="individualReviewRatingDivItemDemo">
                                <p>VALUE:</p>
                                <h3>{5}</h3>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="individualReviewNotesDivDemo">
                        <p>{writtenNotesSecondDemo}</p>
                      </div>{" "}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {/* <div key={1} className="individualReviewDiv">
                    <div className="individualReviewDateDiv">
                      <img
                        src={require("./starfruitSingleLogo.png")}
                        style={{ width: "25px" }}
                      />
                      <p className="reviewDate">Dec 09 2022</p>
                    </div>
                    <div className="individualReviewRatingDiv">
                      <div className="summaryDiv" style={{ paddingTop: "3px" }}>
                        <div>
                          <p style={{ marginRight: "8px" }}>OVERALL: </p>

                          <Ratings
                            rating={4}
                            widgetRatedColors="rgb(255, 213, 0)"
                            widgetSpacings="2px"
                            widgetHoverColors="#fbc70c"
                            widgetDimensions="22px"
                            widgetEmptyColors="rgb(241, 241, 244)"
                          >
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                          </Ratings>
                          <button
                            className="individualReviewMoreLargeButton"
                            onClick={() => {
                              clickedIndex == 1
                                ? setClickedIndex(null)
                                : setClickedIndex(1);
                            }}
                          >
                            More Stats
                          </button>
                          <button
                            className="individualReviewMoreSmallButton"
                            onClick={() => {
                              clickedIndex == 1
                                ? setClickedIndex(null)
                                : setClickedIndex(1);
                            }}
                          >
                            <FontAwesomeIcon
                              className="icon"
                              icon="fa-solid fa-arrow-down"
                            />
                          </button>
                        </div>
                        {clickedIndex == 1 && (
                          <div className="individualReviewRatingDivDiv">
                            <div
                              className="individualReviewRatingDivItem"
                              style={{ marginRight: "9px" }}
                            >
                              <p>FOOD: </p>
                              <h3>{5}</h3>
                            </div>
                            <div
                              className="individualReviewRatingDivItem"
                              style={{ marginRight: "9px" }}
                            >
                              <p>SERVICE:</p>
                              <h3>{4}</h3>
                            </div>
                            <div
                              className="individualReviewRatingDivItem"
                              style={{ marginRight: "9px" }}
                            >
                              <p>SETTING:</p>
                              <h3>{5}</h3>
                            </div>
                            <div className="individualReviewRatingDivItem">
                              <p>VALUE:</p>
                              <h3>{4}</h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="individualReviewNotesDiv">
                      <p>writtenv</p>
                    </div>{" "}
                  </div> */}
                  <p
                    style={{
                      fontSize: "17px",
                      paddingTop: "10px",
                      color: "rgb(180, 180, 180)",
                    }}
                  >
                    {" "}
                    Login to view your raw data, metrics, visualization, and
                    more!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            // height: "500px",
            paddingBottom: "80px",
            // backgroundImage: "linear-gradient(#FFBE1F, #FFDC29)",
            backgroundImage: "linear-gradient(to top right, #FFBE1F, #FFDC29)",
          }}
          className="businesspageBodySixthBackground"
        >
          <div className="businesspageBodySixthBackgroundInner">
            <div className="businesspageBodySixthBackgroundInnerInner">
              <h1>Business Testimonials</h1>
              {/* <p style={{ fontSize: "17px", color: "rgb(169 132 0)" }}>
                A word from some of our Starfruit users.
              </p> */}
              <div className="ownerProfileOuter">
                <div className="ownerProfile">
                  <div className="ownerProfileInner">
                    <div class="ownerProfilePic">
                      <img
                        // style={{ border: "2px solid rgb(255, 213, 0)" }}
                        src={require("./ownerOneIcon.png")}
                      ></img>
                    </div>
                    <div className="ownerProfileText">
                      <h3>Diya, </h3>
                      <h5>Chef</h5>
                      <h4>San Francisco, CA</h4>
                      {/* <p style={{}}>
                      <FontAwesomeIcon
                        class="quoteIcon"
                        icon="fa-solid fa-quote-left"
                        style={{
                          width: "17px",
                          marginRight: "7px",
                          marginBottom: "-5px",
                        }}
                      />
                      The cooking staff often uses Starfruit feedback to decide
                      what to keep on the menu and, if necessary, how to adjust
                      our cooking.
                      Overall, I've seen a definite improvement in
                      guests' reception of our food, and I hope to continue
                      using Starfruit to maintain this quality.
                      <FontAwesomeIcon
                        class="quoteIcon"
                        icon="fa-solid fa-quote-right"
                        style={{
                          width: "17px",
                          marginLeft: "7px",
                          marginBottom: "-7px",
                        }}
                      />
                    </p> */}
                    </div>
                  </div>
                  <p className="ownerProfileTextLarge">
                    <FontAwesomeIcon
                      class="quoteIcon"
                      icon="fa-solid fa-quote-left"
                      style={{
                        width: "16px",
                        marginRight: "7px",
                        marginBottom: "-5px",
                      }}
                    />
                    The cooking staff often uses Starfruit feedback to decide
                    what to keep on the menu and, if necessary, how to adjust
                    our cooking.
                    <FontAwesomeIcon
                      class="quoteIcon"
                      icon="fa-solid fa-quote-right"
                      style={{
                        width: "16px",
                        marginLeft: "7px",
                        marginBottom: "-7px",
                      }}
                    />
                  </p>
                </div>
                <div className="ownerProfile">
                  <div className="ownerProfileInner">
                    <div class="ownerProfilePic">
                      <img
                        // style={{ border: "2px solid rgb(255, 213, 0)" }}
                        src={require("./ownerTwoIcon.png")}
                      ></img>
                    </div>
                    <div className="ownerProfileText">
                      <h3>Derek, </h3>
                      <h5>Owner</h5>
                      <h4>Berkeley, CA</h4>
                      {/* <p style={{}}>
                      <FontAwesomeIcon
                        class="quoteIcon"
                        icon="fa-solid fa-quote-left"
                        style={{
                          width: "17px",
                          marginRight: "7px",
                          marginBottom: "-5px",
                        }}
                      />
                      I would definitely recommend Starfruit to other business
                  owners. I've used it so far to adjust staffing, furniture, and
                  pricing.
                      I would definitely recommend Starfruit to other business
                      owners. I've used it so far to adjust staffing, furniture,
                      and pricing.
                      <FontAwesomeIcon
                        class="quoteIcon"
                        icon="fa-solid fa-quote-right"
                        style={{
                          width: "17px",
                          marginLeft: "7px",
                          marginBottom: "-7px",
                        }}
                      />
                    </p> */}
                    </div>
                  </div>
                  <p className="ownerProfileTextLarge">
                    <FontAwesomeIcon
                      class="quoteIcon"
                      icon="fa-solid fa-quote-left"
                      style={{
                        width: "16px",
                        marginRight: "7px",
                        marginBottom: "-5px",
                      }}
                    />
                    Starfruit has been highly effective in helping me adjust
                    staffing resources, furniture, and pricing.
                    <FontAwesomeIcon
                      class="quoteIcon"
                      icon="fa-solid fa-quote-right"
                      style={{
                        width: "16px",
                        marginLeft: "7px",
                        marginBottom: "-7px",
                      }}
                    />
                  </p>
                </div>
                <div className="ownerProfile">
                  <div className="ownerProfileInner">
                    <div class="ownerProfilePic">
                      <img
                        // style={{ border: "2px solid rgb(255, 213, 0)" }}
                        src={require("./ownerThreeIcon.png")}
                      ></img>
                    </div>
                    <div className="ownerProfileText">
                      <h3>Henry, </h3>
                      <h5>Owner</h5>
                      <h4>San Jose, CA</h4>
                      {/* <p style={{}}>
                      <FontAwesomeIcon
                        class="quoteIcon"
                        icon="fa-solid fa-quote-left"
                        style={{
                          width: "17px",
                          marginRight: "7px",
                          marginBottom: "-5px",
                        }}
                      />
                       Starfruit has been a great tool in understanding what
                  customers are thinking when they visit my restaurant. The
                  amount of guest information has quadrupled since adding
                  Starfruit QR codes on our tables.
                      Additional guest information has been exponential since
                      adding Starfruit QR codes on our tables.
                      <FontAwesomeIcon
                        class="quoteIcon"
                        icon="fa-solid fa-quote-right"
                        style={{
                          width: "17px",
                          marginLeft: "7px",
                          marginBottom: "-7px",
                        }}
                      />
                    </p> */}
                    </div>
                  </div>
                  <p className="ownerProfileTextLarge">
                    <FontAwesomeIcon
                      class="quoteIcon"
                      icon="fa-solid fa-quote-left"
                      style={{
                        width: "16px",
                        marginRight: "7px",
                        marginBottom: "-5px",
                      }}
                    />
                    Additional guest information has been exponential since
                    adding Starfruit QR codes on our tables.
                    <FontAwesomeIcon
                      class="quoteIcon"
                      icon="fa-solid fa-quote-right"
                      style={{
                        width: "16px",
                        marginLeft: "7px",
                        marginBottom: "-7px",
                      }}
                    />
                  </p>
                </div>
                {/* <div className="ownerProfile">
                <img
                  class="ownerProfilePic"
                  src={require("./OwnerOne.jpeg")}
                ></img>
                <div className="ownerProfileText">
                  <h3>Jenny: SF, CA</h3>
                  <p>hey hey hey hey hey hey</p>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            // height: "500px",
            paddingBottom: "110px",
            // backgroundImage: "linear-gradient(#FFBE1F, #FFDC29)",
          }}
          className="businesspageBodyFifthBackground"
        >
          <div className="businesspageBodyFifthBackgroundInner">
            <div
              // style={{ width: "100%", margin: "auto" }}
              className="businesspageBodyFifthBackgroundInnerInner"
            >
              <h1>Participating Restaurants</h1>
              <p>Some of our QR Codes in action throughout California.</p>
            </div>
            <AutoplaySlider
              play={true}
              cancelOnInteraction={false} // should stop playing on user interaction
              interval={3500}
              className="autoplaySlider"
            >
              <div
                className="autoplaySliderPage"
                style={{
                  maxHeight: "350px",
                  margin: "auto",
                  borderRadius: "8px",
                }}
                data-src={require("./participatingRestaurantQROneNew.png")}
              />

              <div
                className="autoplaySliderPage"
                style={{
                  // maxWidth: "900px",
                  maxHeight: "350px",
                  margin: "auto",
                  borderRadius: "8px",
                }}
                data-src={require("./participatingRestaurantQRTwo.png")}
              />
              <div
                className="autoplaySliderPage"
                style={{
                  // maxWidth: "900px",
                  maxHeight: "350px",
                  margin: "auto",
                  borderRadius: "8px",
                }}
                data-src={require("./participatingRestaurantQRThree.png")}
              />
              {/* <div
                className="autoplaySliderPage"
                style={{
                  maxHeight: "350px",
                  margin: "auto",
                  borderRadius: "8px",
                }}
                data-src={require("./participatingRestaurantFour.jpg")}
              />
              <div
                className="autoplaySliderPage"
                style={{
                  maxHeight: "350px",
                  margin: "auto",
                  borderRadius: "8px",
                }}
                data-src={require("./participatingRestaurantFive.jpg")}
              /> */}
            </AutoplaySlider>
          </div>
        </div>

        <div className="businesspageBodyThirdBackground">
          <div className="faqDivOuter">
            <div className="faqDivInner">
              <h1>FAQ's</h1>
              <button
                id="loginButton"
                className="login-with-google-btn"
                onClick={signInWithGoogle}
              >
                Login
              </button>
              <button
                id="getQRCodeButton"
                className="login-with-google-btn"
                onClick={() => navigate("/qrcode")}
              >
                Get QR Code
              </button>
              <div className="faqDivInnerInner">
                <Faq
                  style={{ paddingBottom: "10px" }}
                  data={data}
                  styles={styles}
                  config={config}
                />
              </div>
            </div>
            <img
              className="starfruitBusinesspageSecondInfographic"
              src={require("./starfruitBusinesspageSecondInfographic.png")}
            ></img>
          </div>
        </div>
      </div>
      <Footer className="footerBusiness" />
    </div>
  );
}

export default Business;
