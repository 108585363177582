import React, { useEffect, useState } from "react";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth, provider, db } from "./firebase-config";
import "./BusinessVerificationNew.css";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";

import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from "react-places-autocomplete";

import NavbarDashboard from "./NavbarDashboard";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMobileScreenButton,
} from "@fortawesome/free-solid-svg-icons";
library.add(faPhone, faMobileScreenButton);

const google = window.google;

function BusinessVerificationNew({ isAuth, setIsAuth }) {
  const navigate = useNavigate();
  const [address, setAddress] = React.useState("");
  const [restaurantID, setRestaurantID] = useState(null);
  const searchOptions = {
    types: ["restaurant", "bakery", "bar", "cafe"],
  };

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [restaurantName, setRestaurantName] = useState();
  // const [verificationCode, setVerificationCode] = useState();
  const [whereHeardStarfruit, setWhereHeardStarfruit] = useState();
  const [reasonUseStarfruit, setReasonUseStarfruit] = useState();

  const [firstPageInstructions, setFirstPageInstructions] = useState();
  const [secondPageInstructions, setSecondPageInstructions] = useState();
  const [thirdPageInstructions, setThirdPageInstructions] = useState();

  const [verificationFormPage, setVerificationFormPage] = useState(1);

  const [formData, setFormData] = useState(null);
  const [formDataOwner, setFormDataOwner] = useState(null);
  const [formRestaurantUpdate, setFormRestaurantUpdate] = useState(null);

  const [restaurantPhone, setRestaurantPhone] = useState();

  const [updatedBusinessOwnersDB, setUpdatedBusinessOwnersDB] = useState(null);
  const [updatedRestaurantsDB, setUpdatedRestaurantsDB] = useState(null);

  const [testRestaurantPhone, setTestRestaurantPhone] =
    useState("+1 415-519-6138");
  const [sentVerificationCode, setSentVerificationCode] = useState(null);
  const [receivedVerificationCode, setReceivedVerificationCode] =
    useState(null);

  const functions = getFunctions();
  // const functions = require("firebase-functions");
  // const sendTexxt = httpsCallable(functions, "sendText");

  const sendCall = (_) => {
    // const { text } = this.state;
    //pass text message GET variables via query string
    // alert("hello");
    const sendCallFirebase = httpsCallable(functions, "sendCall");

    if (!sentVerificationCode) {
      // alert("set verification code");
      var result = "";
      var characters = "0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      setSentVerificationCode(result);
      // fetch(
      //   `http://127.0.0.1:4000/send-call?recipient=${testRestaurantPhone
      //     .replace(/-/g, "")
      //     .replace(/\s/g, "")}&textmessage=${result.split("").join(". ")}`
      // ).catch((err) => console.error(err));
      // fetch(
      //   `https://us-central1-starfruit-350705.cloudfunctions.net/sendCall?recipient=${testRestaurantPhone
      //     .replace(/-/g, "")
      //     .replace(/\s/g, "")}&textmessage=${result.split("").join(". ")}`
      // ).catch((err) => console.error(err));

      sendCallFirebase({
        recipient: `${restaurantPhone.replace(/-/g, "").replace(/\s/g, "")}`,
        textmessage: `${result.split("").join(". ")}`,
      }).then(
        (res) => {
          console.log(res);
          const data = res.data;
          const recipient = data.recipient;
          const textmessage = data.textmessage;
        },
        (err) => {
          console.log(err);
        }
      );
    }

    // fetch(
    //   `http://127.0.0.1:4000/send-call?recipient=${testRestaurantPhone
    //     .replace(/-/g, "")
    //     .replace(/\s/g, "")}&textmessage=${sentVerificationCode
    //     .split("")
    //     .join(". ")}`
    // ).catch((err) => console.error(err));
    // fetch(
    //   `https://us-central1-starfruit-350705.cloudfunctions.net/sendCall?recipient=${testRestaurantPhone
    //     .replace(/-/g, "")
    //     .replace(/\s/g, "")}&textmessage=${sentVerificationCode
    //     .split("")
    //     .join(". ")}`
    // ).catch((err) => console.error(err));

    sendCallFirebase({
      recipient: `${restaurantPhone.replace(/-/g, "").replace(/\s/g, "")}`,
      textmessage: `${sentVerificationCode.split("").join(". ")}`,
    }).then(
      (res) => {
        const data = res.data;
        const recipient = data.recipient;
        const textmessage = data.textmessage;
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const sendText = (_) => {
    var result = "";

    const sendTextFirebase = httpsCallable(functions, "sendText");

    if (!sentVerificationCode) {
      // alert("set verification code");
      var characters = "0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      setSentVerificationCode(result);
      // fetch(
      //   `http://127.0.0.1:4000/send-text?recipient=${testRestaurantPhone
      //     .replace(/-/g, "")
      //     .replace(
      //       /\s/g,
      //       ""
      //     )}&textmessage=Your Starfruit Verification Code is: ${result}`
      // ).catch((err) => console.error(err));
      // fetch(
      //   `https://us-central1-starfruit-350705.cloudfunctions.net/sendText?recipient=${testRestaurantPhone
      //     .replace(/-/g, "")
      //     .replace(
      //       /\s/g,
      //       ""
      //     )}&textmessage=Your Starfruit Verification Code is: ${result}`
      // ).catch((err) => console.error(err));

      // sendTexxt({ text: "hihi" }).then((result) => {
      //   /** @type {any} */
      //   const data = result.data;
      //   const sanitizedMessage = data.text;
      // });

      sendTextFirebase({
        recipient: `${restaurantPhone.replace(/-/g, "").replace(/\s/g, "")}`,
        textmessage: `Your Starfruit Verification Code is: ${result}`,
      }).then(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      // fetch(
      //   `http://127.0.0.1:4000/send-text?recipient=${testRestaurantPhone
      //     .replace(/-/g, "")
      //     .replace(
      //       /\s/g,
      //       ""
      //     )}&textmessage=Your Starfruit Verification Code is: ${sentVerificationCode}`
      // ).catch((err) => console.error(err));
      // fetch(
      //   `https://us-central1-starfruit-350705.cloudfunctions.net/sendText?recipient=${testRestaurantPhone
      //     .replace(/-/g, "")
      //     .replace(
      //       /\s/g,
      //       ""
      //     )}&textmessage=Your Starfruit Verification Code is: ${sentVerificationCode}`
      // ).catch((err) => console.error(err));

      sendTextFirebase({
        recipient: `${restaurantPhone.replace(/-/g, "").replace(/\s/g, "")}`,
        textmessage: `Your Starfruit Verification Code is: ${sentVerificationCode}`,
      }).then(
        (res) => {
          console.log(res);
          const data = res.data;
          const recipient = data.recipient;
          const textmessage = data.textmessage;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    setRestaurantID(results[0].place_id);
    var restaurantName = value.substr(0, value.indexOf(","));
    setRestaurantName(restaurantName);
    // setAddress(value);
    // console.log(value);
    setAddress(restaurantName);
  };

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      window.location.pathname = "/business";
    });
  };

  // useEffect(() => {
  //   alert("set verification code");
  //   var result = "";
  //   var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  //   var charactersLength = characters.length;
  //   for (var i = 0; i < 6; i++) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }
  //   setSentVerificationCode(result);
  // }, []);

  function NextOne() {
    if (!firstName || !lastName || !address) {
      setFirstPageInstructions("Please add your name and restaurant.");
      return;
    } else {
      // console.log(restaurantID);
      var service = new google.maps.places.PlacesService(
        document.getElementById("sister")
      );
      service.getDetails(
        {
          placeId: restaurantID,
        },
        function (place, status) {
          // console.log("Place details:", place);
          setRestaurantPhone(place.international_phone_number);
        }
      );
      setVerificationFormPage(2);
      setFirstPageInstructions("");
    }
  }

  function NextTwo() {
    // alert(sentVerificationCode);
    // alert(testRestaurantPhone);
    if (!receivedVerificationCode) {
      setFirstPageInstructions("Please enter the code sent.");
      return;
    }
    if (receivedVerificationCode !== sentVerificationCode) {
      setFirstPageInstructions(
        // "The verification code you provided does not match the one sent."
        "The code you entered is incorrect."
      );
      return;
    } else {
      setVerificationFormPage(3);
      setFirstPageInstructions("");

      // alert(sentVerificationCode);
    }
  }

  function BackTwo() {
    setFirstPageInstructions("");
    setVerificationFormPage(1);
  }

  function BackThree() {
    setFirstPageInstructions("");
    setVerificationFormPage(2);
  }

  // useEffect(() => {
  //   functions()
  //     .httpsCallable('sendText')()
  //     .then(response => {
  //       setProducts(response.data);
  //       setLoading(false);
  //     });
  // }, []);

  // useEffect(() => {
  //   functions()
  //     .httpsCallable('sendCall')()
  //     .then(response => {
  //       setProducts(response.data);
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    // console.log("Rest");
    // console.log(updatedRestaurantsDB);
    if (updatedBusinessOwnersDB && updatedRestaurantsDB) {
      const navigateAnalytics = async () => {
        // alert("hi");
        const userUID = window.location.href.substring(
          window.location.href.indexOf("id=") + 3
        );
        navigate(`/businessanalytics/id=${userUID}`);
      };
      navigateAnalytics();
      // alert("brother");
      // navigate(`/businessanalytics/id=Rb1Ud0JfnmhqZv7LFwXvu0OCgO72`);
    }
  }, [updatedRestaurantsDB]);

  useEffect(() => {
    // console.log("BO");
    // console.log(updatedBusinessOwnersDB);
    if (updatedBusinessOwnersDB && updatedRestaurantsDB) {
      const navigateAnalytics = async () => {
        // alert("hi");
        const userUID = window.location.href.substring(
          window.location.href.indexOf("id=") + 3
        );
        navigate(`/businessanalytics/id=${userUID}`);
      };
      navigateAnalytics();
      // alert("brother");
      // navigate(`/businessanalytics/id=Rb1Ud0JfnmhqZv7LFwXvu0OCgO72`);
    }
  }, [updatedBusinessOwnersDB]);

  useEffect(() => {
    // alert("hi");
    // console.log(formDataOwner);
    if (formDataOwner) {
      // alert("bitch");
      const getOwner = async () => {
        const docData = {
          restaurant_google_places_ID: restaurantID,
          restaurant_name: restaurantName,
          owner_first_name: firstName,
          owner_last_name: lastName,
          business_account_date_verified: Timestamp.now(),
          business_account_use_reason: reasonUseStarfruit,
          business_account_where_heard: whereHeardStarfruit,
          business_account_verification_status: "passed verification",
          business_account_phone_number: restaurantPhone,
        };
        updateDoc(
          doc(db, "business owners", formDataOwner[0].id),
          docData
        ).then(() => {
          window.location.reload(false);
        });
        setUpdatedBusinessOwnersDB(true);
      };
      getOwner();
      // navigate(`/businessanalytics/id=Rb1Ud0JfnmhqZv7LFwXvu0OCgO72`);
    }
  }, [formDataOwner]);

  useEffect(() => {
    if (formRestaurantUpdate) {
      const updateRestaurant = async () => {
        const docData = {
          owner_first_name: firstName,
          owner_last_name: lastName,
          business_account_phone_number: restaurantPhone,
        };
        updateDoc(
          doc(db, "restaurants", formRestaurantUpdate[0].id),
          docData
        ).then(() => {
          window.location.reload(false);
        });
        setUpdatedRestaurantsDB(true);
      };
      updateRestaurant();
    }
  }, [formRestaurantUpdate]);

  function Submit() {
    if (!whereHeardStarfruit || !reasonUseStarfruit) {
      setFirstPageInstructions("Please select for both questions.");
    } else {
      setFirstPageInstructions("");

      const qqqqqq = query(
        collection(db, "restaurants"),
        where("restaurant_google_places_ID", "==", restaurantID)
      );

      const updateRestaurantDatabase = async () => {
        const data = await getDocs(qqqqqq);
        setFormData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        //restaurant not in database, set restaurant, add review
        if (data.empty) {
          // alert("restaurant not in database");
          const docRef = await addDoc(collection(db, "restaurants"), {
            restaurant_owner_date_registered: Timestamp.now(),
            // restaurant_first_reviewer: "",
            restaurant_google_places_ID: restaurantID,
            restaurant_name: restaurantName,
            owner_first_name: firstName,
            owner_last_name: lastName,
            restaurant_overall_pts: 0,
            restaurant_food_pts: 0,
            restaurant_service_pts: 0,
            restaurant_setting_pts: 0,
            restaurant_value_pts: 0,
            restaurant_total_reviews: 0,
            restaurant_total_food_reviews: 0,
            restaurant_total_service_reviews: 0,
            restaurant_total_setting_reviews: 0,
            restaurant_total_value_reviews: 0,
            business_account_phone_number: restaurantPhone,
          });
        } else {
          // const updateRestaurant = async () => {
          //   const docData = {
          //     owner_first_name: firstName,
          //     owner_last_name: lastName,
          //     business_account_phone_number: restaurantPhone,
          //   };
          //   updateDoc(
          //     doc(db, "restaurants", formDataOwner[0].id),
          //     docData
          //   ).then(() => {
          //     window.location.reload(false);
          //   });
          // };
          // updateRestaurant();
          const qqqq = query(
            collection(db, "restaurants"),
            where("restaurant_google_places_ID", "==", restaurantID)
          );
          const dataaa = await getDocs(qqqq);
          setFormRestaurantUpdate(
            dataaa.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        }

        const qqq = query(
          collection(db, "business owners"),
          where(
            "userUID",
            "==",
            window.location.href.substring(
              window.location.href.indexOf("id=") + 3
            )
          )
        );
        // alert(
        //   window.location.href.substring(
        //     window.location.href.indexOf("id=") + 3
        //   )
        // );
        const dataa = await getDocs(qqq);
        setFormDataOwner(
          dataa.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      };
      updateRestaurantDatabase();
    }
  }

  return (
    <div className="businessVerificationNew">
      <div className="verificationWhiteBackground"></div>
      <div id="sister"></div>
      <NavbarDashboard />
      <button className="signOutButton" onClick={signUserOut}>
        Logout
      </button>
      <div className="businessVerificationPageNumber">
        <p>Step {verificationFormPage} of 3</p>
      </div>

      <h3>Welcome! Please Verify Your Business</h3>
      <h1>Business Verification Form</h1>
      <div></div>
      <p className="verificationError">
        {firstPageInstructions}
        {secondPageInstructions}
        {thirdPageInstructions}
      </p>
      {/* <div className="businessVerificationNewForm"> */}
      <form>
        <div
          className="businessVerificationFirstPage"
          style={{
            marginLeft:
              verificationFormPage === 1
                ? "0%"
                : verificationFormPage === 2
                ? "-33.33%"
                : "-66.66%",
          }}
        >
          <div className="businessVerificationContent">
            <p>First Name *</p>
            <input
              placeholder="Jane"
              onChange={(event) => setFirstName(event.target.value)}
            />
            <p>Last Name *</p>
            <input
              placeholder="Doe"
              onChange={(event) => setLastName(event.target.value)}
            />
            <p>Your Business *</p>
            <PlacesAutocomplete
              // placeholder="[Restaurant Name]"
              name="address"
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
              searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    name="restaurant"
                    {...getInputProps({
                      placeholder: "Enter Restaurant Name",
                    })}
                  />
                  {/* div for all the suggestions */}
                  <div className="verificationFormRestaurantSuggestionsOuter">
                    {loading ? <div>...loading</div> : null}

                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active
                          ? "rgba(256,212,36, 1)"
                          : "#fff",
                        fontSize: "15px",
                        paddingBottom: "8px",
                        paddingTop: "8px",
                        marginLeft: "-7px",
                        marginRight: "-7px",
                        marginTop: "3px",
                        marginBottom: "4px",
                      };

                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <div className="businessVerificationButtonField">
              <button type="button" onClick={() => NextOne()}>
                Next
              </button>
            </div>
          </div>
        </div>
        <div className="businessVerificationSecondPage">
          <div className="businessVerificationContent">
            <p>
              Verify your Business Number:
              <h5>{restaurantPhone}</h5>
            </p>
            <p
              style={{
                color: "rgb(180, 180, 180)",
                fontWeight: "400",
                marginTop: "0px",
              }}
            >
              Allow a couple seconds for the message to send after pressing.
              {/* If
              there is no phone number above, your business needs to submit your
              number to Google. */}
              {/* (Taken from Google) */}
            </p>
            <div className="businessPhoneVerification">
              <button type="button" onClick={() => sendText()}>
                <FontAwesomeIcon
                  className="phoneVerificationIcon"
                  icon="fa-solid fa-mobile-screen-button"
                  style={{ height: "18px" }}
                />
                Text Me the Code
              </button>
              <button
                style={{ marginBottom: "2px" }}
                type="button"
                onClick={() => sendCall()}
              >
                <FontAwesomeIcon
                  className="phoneVerificationIcon"
                  icon="fa-solid fa-phone"
                  style={{ height: "16px" }}
                />
                Call Me the Code
              </button>
            </div>
            <p>Enter your Verification Code *</p>
            <input
              placeholder="5 digits"
              onChange={(e) => setReceivedVerificationCode(e.target.value)}
            />
            <div className="businessVerificationButtonField">
              <button type="button" onClick={() => BackTwo()}>
                Back
              </button>
              <button type="button" onClick={() => NextTwo()}>
                Next
              </button>
            </div>
          </div>
        </div>
        <div className="businessVerificationThirdPage">
          <div className="businessVerificationContent">
            <p>How did you hear about Starfruit? *</p>
            <select onChange={(e) => setWhereHeardStarfruit(e.target.value)}>
              <option disabled selected value>
                Choose an Option
              </option>
              <option value="Direct Email">Direct Email from Starfruit</option>
              <option value="Direct Call">Direct Call from Starfruit</option>
              <option value="Another Business">
                Another Business Word of Mouth
              </option>
              <option value="Customer">Customer Word of Mouth</option>
              <option value="Instagram">Instagram</option>
              <option value="Facebook">Facebook</option>
            </select>
            <p>What is the main reason you are hoping to use Starfruit? *</p>
            <select onChange={(e) => setReasonUseStarfruit(e.target.value)}>
              <option disabled selected value>
                Choose an Option
              </option>
              <option value="Food">Review Food Quality and Taste</option>
              <option value="Service">
                Review Service and Employee Performance
              </option>
              <option value="Setting">Review Setting and Atmosphere</option>
              <option value="Price">Review Price and Value</option>
              {/* <option value="communicate with customers">
                Communicate with Customers
              </option> */}
            </select>
            {/* <p>Why are you hoping to use Starfruit?</p>
            <select>
              <option disabled selected value>
                Choose an Option
              </option>
              <option value="review customer feedback">
                Review Customer Feedback
              </option>
              <option value="communicate with customers">
                Communicate with Customers
              </option>
              <option value="analyze employee service">
                Analyze Employee Service
              </option>
            </select> */}
            <div className="businessVerificationButtonField">
              <button type="button" onClick={() => BackThree()}>
                Back
              </button>
              <button type="button" onClick={() => Submit()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* </div> */}
    </div>
  );
}

export default BusinessVerificationNew;
