// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC4_VdLc570H5ErkRDCs-GPboVlBeYoAPk",
  authDomain: "starfruit-350705.firebaseapp.com",
  projectId: "starfruit-350705",
  storageBucket: "starfruit-350705.appspot.com",
  messagingSenderId: "15180431421",
  appId: "1:15180431421:web:3bdb4fbb99f2eaa6782a54",
  measurementId: "G-WWQ92D45RH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// const storage = getStorage(app);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const storage = getStorage(app);
export const functions = getFunctions(app);
