import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "./firebase-config";
import "./Navbar.css";

import "../src/starfruittitle.png";

function Navbar({ loggedIn }) {
  const navigate = useNavigate();
  // const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));

  // // let navigate = useNavigate()

  // const signUserOut = () => {
  //     signOut(auth).then(()=> {
  //       localStorage.clear();
  //       setIsAuth(false);
  //       window.location.pathname = "/business";
  //       // navigate('/business');
  //     });
  // };
  return (
    <div className="navbarBody">
      <nav>
        {/* <p>Starfruit</p> */}
        <img
          onClick={() => navigate("/")}
          src={require("./starfruittitle.png")}
        ></img>

        <div className="navbarLinks">
          <Link className="navbarLink" to="/">
            Home
          </Link>
          <Link className="navbarLink" to="/business">
            Business
          </Link>
        </div>

        {/* {!isAuth ? (
          <Link to="/businessdashboard"> Login </Link>
        ) : (
          <>
            <button onClick={signUserOut}> Log Out</button>
          </>
        )} */}
      </nav>
    </div>
  );
}

export default Navbar;
