import React from "react";
import "./BusinessVerificationProofofOwnership.css";

function BusinessVerificationProofofOwnership() {
  return (
    <div className="businessVerificationProofofOwnershipWhole">
      {/* <img src={require("./starfruitProofofBusiness.png")}></img> */}
      <div className="navbarDashboardColorBackground">
        <nav>
          <img src={require("./starfruittitle.png")}></img>
        </nav>
      </div>
      <h1>Acceptable Proof of Ownership</h1>
      <p>
        Please upload a png/jpg/pdf of your Yelp or Google Business Account
        profile. For Yelp, go to the "Your Business" tab and click "Business
        Information." For Google, go to "My Business" and hit "Info."
      </p>
      <div className="businessVerificationProofofOwnershipExamples">
        <div className="businessVerificationProofofOwnershipSubtitle">
          <h2>1. Yelp Business Account</h2>
        </div>
        <img src={require("./Screen Shot 2022-07-24 at 3.02.59 PM.png")}></img>
        <div className="businessVerificationProofofOwnershipSubtitle">
          <h2>2. Google Business Account</h2>
        </div>
        <img src={require("./Screen Shot 2022-07-24 at 3.03.37 PM.png")}></img>
      </div>
    </div>
  );
}

export default BusinessVerificationProofofOwnership;
