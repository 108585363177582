import { faAppleAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import "./BusinessVerificationUnderReview.css";

function BusinessVerificationUnderReview() {
  // const twilio = require("twilio");
  // const express = require("express");
  // // const app = express()
  // // const port =

  // // const accountSid = process.env.AC6e2df1e590884d9cf413b6e8968aeac4;
  // // const authToken = process.env.48e5ef51b39047f506bcd12f8f4e1ca7;
  // const client = require("twilio")(
  //   "AC6e2df1e590884d9cf413b6e8968aeac4",
  //   "48e5ef51b39047f506bcd12f8f4e1ca7"
  // );

  // useEffect(() => {
  //   function sendTextMessage() {
  //     alert("broski");
  //     client.messages
  //       .create({
  //         body: "Hello! :D",
  //         to: "+14158067243",
  //         from: "+14155196138",
  //       })
  //       .then((message) => console.log(message))
  //       .catch((error) => console.log(error));
  //   }
  //   sendTextMessage();
  // });

  // client.verify.v2.services('VAXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
  //                 .verifications
  //                 .create({to: '+15017122661', channel: 'sms'})
  //                 .then(verification => console.log(verification.status));

  return (
    <div>
      <h3>Welcome!</h3>
      <div className="navbarDashboardColorBackground">
        <nav>
          <img src={require("./starfruittitle.png")}></img>
        </nav>
      </div>
      <h1>Verification In Progress</h1>
      <div className="businessVerificationUnderReview">
        <h2>Thank you for submitting the form!</h2>
        <p>
          The information you've provided will be reviewed within 1-2 business
          days
        </p>
        <h2>Questions in the Meantime?</h2>
        <p>email us at info@starfruittech.com</p>
        <h2>Stay Updated on our Instagram</h2>
        <p>@starfruit.tech updates regularly</p>
      </div>
    </div>
  );
}

export default BusinessVerificationUnderReview;
