import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import ReactDOM from "react-dom";
import { SocialIcon } from "react-social-icons";

function Footer() {
  return (
    <div className="footerBody">
      {/* <h4>Questions? Reach Us At</h4> */}
      {/* <p>reach us at</p> */}
      <div className="footerCopyright">
        <p>© 2022 Starfruit Tech</p>
      </div>
      <div className="footerLinks">
        {/* <div className="hello"> */}
        {/* <p style={{ display: "inline-block" }}>Email: </p> */}
        <div className="footerIcon">
          <SocialIcon
            style={{ height: 35, width: 35 }}
            url="mailto:info@starfruittech.com"
            bgColor="white"
            // fgColor="#ac843c"
          />
          {/* <p>starfruithelp@gmail.com</p> */}
        </div>
        {/* <p style={{ display: "inline-block" }}>Instagram: </p> */}
        <div className="footerIcon">
          <SocialIcon
            style={{ height: 35, width: 35 }}
            url="https://instagram.com/starfruit.tech"
            bgColor="white"
            target="_blank"
          />
          {/* <p>@starfruit.tech</p> */}
        </div>
        {/* </div> */}
      </div>
      {/* <Link to = "/privacypolicy">Privacy Policy</Link> */}
    </div>
  );
}

export default Footer;
