import React, { useEffect, useState } from "react";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth, provider, db } from "./firebase-config";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import Ratings from "react-ratings-declarative";
import BusinessAnalytics from "./BusinessAnalytics";
import BusinessVerification from "./BusinessVerification";
import BusinessVerificationNew from "./BusinessVerificationNew";
import BusinessVerificationFailed from "./BusinessVerificationFailed";
import BusinessVerificationUnderReview from "./BusinessVerificationUnderReview";
import "./BusinessDashboard.css";
import NavbarDashboard from "./NavbarDashboard";

function BusinessDashboard({ isAuth, setIsAuth }) {
  const [userVerified, setUserVerified] = useState([]);

  // const [businessAccountData, setBusinessAccountData] = useState(null);

  const [user, setUser] = useState(null);

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      window.location.pathname = "/business";
    });
  };

  // //reviews list
  // useEffect(() => {
  //   const getReviews = async () => {
  //     const data = await getDocs(q);
  //     setReviewsList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   };
  //   getReviews();
  // }, []);

  // //restaurant data list
  // useEffect(() => {
  //   const getRestaurantData = async () => {
  //     const data = await getDocs(qq);
  //     setRestaurantData(
  //       data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
  //     );
  //   };
  //   getRestaurantData();
  // }, []);

  //verified user
  useEffect(() => {
    if (user) {
      const qqqq = query(
        collection(db, "business owners"),
        where("userUID", "==", user.uid)
      );
      const getUserVerified = async () => {
        const data = await getDocs(qqqq);
        setUserVerified(
          data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );

        if (data.empty) {
          // alert("user not in database");
          const docRef = await addDoc(collection(db, "business owners"), {
            userUID: user.uid,
            business_account_date_created: "date",
            business_account_verification_status: "not yet reviewed",
          });
          console.log("Document written with ID: ", docRef.id);
          window.location.reload();
        } else {
          // alert("user in database");
        }
      };
      getUserVerified();
    }
  }, [user]);

  // //user data
  // useEffect(() => {
  //   const getUsers = async () => {
  //     const data = await getDocs(qqq);
  //     setBusinessAccountData(
  //       data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
  //     );
  //   };
  //   getUsers();
  // }, []);

  return (
    <div className="dashboardDiv">
      <NavbarDashboard />
      <button className="signOutButton" onClick={signUserOut}>
        Logout
      </button>
      <div className="dashboardBodyDiv">
        {userVerified.length > 0 &&
          userVerified[0].business_account_verification_status ===
            "passed verification" && <BusinessAnalytics user={user} />}

        {/* {userVerified.length > 0 &&
          userVerified[0].business_account_verification_status ===
            "not yet reviewed" && <BusinessVerification user={user} />} */}
        {userVerified.length > 0 &&
          userVerified[0].business_account_verification_status ===
            "not yet reviewed" && <BusinessVerificationNew user={user} />}

        {userVerified.length > 0 &&
          userVerified[0].business_account_verification_status ===
            "failed verification" && <BusinessVerificationFailed />}

        {userVerified.length > 0 &&
          userVerified[0].business_account_verification_status ===
            "under review" && <BusinessVerificationUnderReview />}
      </div>
    </div>
  );
}

export default BusinessDashboard;
