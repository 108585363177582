import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Stars from "./Stars";
import "./FeedbackForm.css";
import { useNavigate } from "react-router-dom";
import Ratings from "react-ratings-declarative";
import { auth, provider, db } from "./firebase-config";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Formik, Form, Field, ErrorMessage, isString } from "formik";
import { TextField } from "./TextField";

import Navbar from "./Navbar";
import { geocodeByPlaceId } from "react-places-autocomplete";

import { Steps } from "antd";
import { DatePicker, Space } from "antd";
// import "antd/dist/antd.css";

function FeedbackForm() {
  const [restaurantID, setRestaurantID] = useState(null);
  const [restaurantName, setRestaurantName] = useState(null);

  const [feedbackModalDisplay, setFeedbackModalDisplay] = useState("inline");

  const [overallRating, setOverallRating] = useState(0);
  const [foodRating, setFoodRating] = useState(0);
  const [serviceRating, setServiceRating] = useState(0);
  const [settingRating, setSettingRating] = useState(0);
  const [valueRating, setValueRating] = useState(0);

  const [dateVisited, setDateVisited] = useState();
  const [writtenNotes, setWrittenNotes] = useState("");
  const [reviewerPhone, setReviewerPhone] = useState("");

  const [pageNumber, setPageNumber] = useState(1);
  const [firstPageInstructions, setFirstPageInstructions] = useState("");
  const [secondPageInstructions, setSecondPageInstructions] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const [restaurantReviewedData, setRestaurantReviewedData] = useState(null);

  // const [yelpLink, setYelpLink] = useState(null);
  // const [googleReviewsLink, setGoogleReviewsLink] = useState(null);
  // const [facebookReviewsLink, setFacebookReviewsLink] = useState(null);

  const onChangeDate = (date, dateString) => {
    // console.log(dateString);
    // console.log(date._d);
    // console.log(date);
    // console.log(date.$d);
    setDateVisited(date.$d);
    // alert(date._d);
  };

  // useEffect(() => {
  //   setYelpLink(`http://www.yelp.com/search?find_desc=${restaurantName}`);
  //   setGoogleReviewsLink(`https://www.google.com/search?q=${restaurantName}`);
  //   setFacebookReviewsLink(
  //     `https://www.facebook.com/search/places/?q=${restaurantName}`
  //   );
  // }, [restaurantName]);

  useEffect(() => {
    const updateRestaurantDatabase = async () => {
      if (!location.state) {
        setRestaurantID(
          window.location.href.substring(
            window.location.href.indexOf("id=") + 3,
            window.location.href.indexOf("name=") - 1
          )
        );
        setRestaurantName(
          decodeURI(
            window.location.href.substring(
              window.location.href.indexOf("name=") + 5,
              window.location.href.length
            )
          )
        );
      } else {
        setRestaurantID(location.state.restaurantID);
        setRestaurantName(location.state.restaurantName);
      }
    };
    updateRestaurantDatabase();
  });

  function NextOne() {
    if (
      overallRating === 0
      // ||
      // foodRating === 0 ||
      // serviceRating === 0 ||
      // settingRating === 0 ||
      // valueRating === 0
    ) {
      if (pageNumber === 2) {
        setPageNumber(1);
        setFirstPageInstructions("Please give an overall rating.");
        return (document
          .getElementsByClassName("feedbackFormBody")
          .item(0).style.marginLeft = "0%");
      } else {
        setFirstPageInstructions("Please give an overall rating.");
      }
      return;
    } else {
      setPageNumber(2);
      setFirstPageInstructions("");
      return (document
        .getElementsByClassName("feedbackFormBody")
        .item(0).style.marginLeft = "-100%");
    }
  }

  function BackTwo() {
    setPageNumber(1);
    setSecondPageInstructions("");
    // setpageInstructions("Please give an overall rating.");
    return (document
      .getElementsByClassName("feedbackFormBody")
      .item(0).style.marginLeft = "0%");
  }

  useEffect(() => {
    const updateRestaurantDatabase = async () => {
      //restaurant in database, set restaurant, add review
      if (restaurantReviewedData) {
        const docData = {
          restaurant_food_pts:
            restaurantReviewedData[0].restaurant_food_pts + foodRating,

          restaurant_overall_pts:
            restaurantReviewedData[0].restaurant_overall_pts + overallRating,
          restaurant_service_pts:
            restaurantReviewedData[0].restaurant_service_pts + serviceRating,
          restaurant_setting_pts:
            restaurantReviewedData[0].restaurant_setting_pts + settingRating,
          restaurant_total_reviews:
            restaurantReviewedData[0].restaurant_total_reviews + 1,
          restaurant_value_pts:
            restaurantReviewedData[0].restaurant_value_pts + valueRating,

          restaurant_total_food_reviews:
            foodRating === 0
              ? restaurantReviewedData[0].restaurant_total_food_reviews
              : restaurantReviewedData[0].restaurant_total_food_reviews + 1,
          restaurant_total_service_reviews:
            serviceRating === 0
              ? restaurantReviewedData[0].restaurant_total_service_reviews
              : restaurantReviewedData[0].restaurant_total_service_reviews + 1,
          restaurant_total_setting_reviews:
            settingRating === 0
              ? restaurantReviewedData[0].restaurant_total_setting_reviews
              : restaurantReviewedData[0].restaurant_total_setting_reviews + 1,
          restaurant_total_value_reviews:
            valueRating === 0
              ? restaurantReviewedData[0].restaurant_total_value_reviews
              : restaurantReviewedData[0].restaurant_total_value_reviews + 1,
        };
        updateDoc(
          doc(db, "restaurants", restaurantReviewedData[0].id),
          docData
        );

        const docRef = await addDoc(collection(db, "reviews"), {
          restaurant_google_places_ID: restaurantID,
          restaurant_name: restaurantName,
          review_date_submitted: Timestamp.now(),
          review_date_visited: dateVisited,
          review_food_rating: foodRating,
          review_num_of_restaurant:
            restaurantReviewedData[0].restaurant_total_reviews + 1,
          review_overall_rating: overallRating,
          review_service_rating: serviceRating,
          review_setting_rating: settingRating,
          // review_submitter_phone: reviewerPhone,
          review_value_rating: valueRating,
          review_written_notes: writtenNotes,
        });
      }
    };
    updateRestaurantDatabase();
  }, [restaurantReviewedData]);

  function pussy() {
    // const timer = setTimeout(()=> {
    //   navigate("/")
    // }, 2000)

    // if (
    //   overallRating === 0 ||
    //   foodRating === 0 ||
    //   serviceRating === 0 ||
    //   settingRating === 0 ||
    //   valueRating === 0
    // ) {
    //   if (pageNumber === 2) {
    //     setPageNumber(1);
    //     setpageInstructions("Please give an overall rating.");
    //     return (document
    //       .getElementsByClassName("feedbackFormBody")
    //       .item(0).style.marginLeft = "0%");
    //   } else {
    //     setpageInstructions("Please give an overall rating.");
    //   }
    //   return;
    // }

    // if (!dateVisited || !reviewerPhone) {
    if (!dateVisited) {
      // setSecondPageInstructions("Please add a date of visit and phone #.");
      setSecondPageInstructions("Please add a date of visit.");
      return;
    }

    const qqqqqq = query(
      collection(db, "restaurants"),
      where("restaurant_google_places_ID", "==", restaurantID)
    );

    const addReviewToDatabase = async () => {
      const data = await getDocs(qqqqqq);
      setRestaurantReviewedData(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      //restaurant not in database, set restaurant, add review
      if (data.empty) {
        // alert("restaurant not in database");
        const docRef = await addDoc(collection(db, "restaurants"), {
          restaurant_owner_registered: "",
          // restaurant_first_reviewer: "",
          restaurant_google_places_ID: restaurantID,
          restaurant_name: restaurantName,
          restaurant_overall_pts: overallRating,
          restaurant_food_pts: foodRating,
          restaurant_service_pts: serviceRating,
          restaurant_setting_pts: settingRating,
          restaurant_value_pts: valueRating,
          restaurant_total_reviews: 1,
          restaurant_total_food_reviews: foodRating === 0 ? 0 : 1,
          restaurant_total_service_reviews: serviceRating === 0 ? 0 : 1,
          restaurant_total_setting_reviews: settingRating === 0 ? 0 : 1,
          restaurant_total_value_reviews: valueRating === 0 ? 0 : 1,
        });
        const docReff = await addDoc(collection(db, "reviews"), {
          restaurant_google_places_ID: restaurantID,
          restaurant_name: restaurantName,
          review_date_submitted: Timestamp.now(),
          review_date_visited: dateVisited,
          // review_date_submitted: moment(Date.now()).format(
          //   "dd.mm.yyyy hh:MM:ss"
          // ),
          review_food_rating: foodRating,
          review_num_of_restaurant: 1,
          review_overall_rating: overallRating,
          review_service_rating: serviceRating,
          review_setting_rating: settingRating,
          // review_submitter_phone: reviewerPhone,
          review_value_rating: valueRating,
          review_written_notes: writtenNotes,
        });
      }
      return (
        (document
          .getElementsByClassName("feedbackGratitudePopup")
          .item(0).style.visibility = "visible"),
        (document
          .getElementsByClassName("feedbackGratitudePopup")
          .item(0).style.opacity = 1)
      );
    };
    addReviewToDatabase();
  }

  return (
    <div className="feedbackFormWhole">
      <div className="feedbackWhiteBackground"></div>
      <div
        className="feedbackFormModal"
        style={{ display: feedbackModalDisplay }}
      >
        <div className="feedbackFormModalInner">
          <h2>
            {/* <img
              onClick={() => navigate("/")}
              src={require("./starfruittitle.png")}
              style={{
                width: "120px",
              }}
            />{" "} */}
            <img
              onClick={() => navigate("/")}
              src={require("./starfruitSingleLogo.png")}
              style={{
                width: "32px",
                marginRight: "3px",
                verticalAlign: "middle",
                marginTop: "-10px",
              }}
            />{" "}
            Starfruit Feedback Is Private
          </h2>
          <div className="feedbackFormModalInnerInner">
            <p>
              Any feedback provided is viewable only by the business owner.
              Therefore, please use this space to only provide kind and
              constructive feedback to support your local businesses!
            </p>

            <h3>I understand and would like to:</h3>
            <button onClick={() => setFeedbackModalDisplay("none")}>
              {/* <img
                onClick={() => navigate("/")}
                src={require("./starfruitSingleLogo.png")}
                style={{
                  width: "32px",
                  marginLeft: "3px",
                  verticalAlign: "middle",
                }}
              /> */}
              Continue{" "}
            </button>
            <div>
              <h3>Leave a public review instead:</h3>
              {/* <div style={{ textAlign: "left" }}> */}
              <div>
                <a
                  href={`http://www.yelp.com/search?find_desc=${restaurantName}`}
                  target="_blank"
                >
                  <img
                    style={{ width: "30px", marginRight: "18px" }}
                    src={require("./yelpRoundLogoNew.png")}
                  />
                </a>
                <a
                  href={`https://www.google.com/search?q=${restaurantName}`}
                  target="_blank"
                >
                  <img
                    style={{
                      width: "30px",
                      marginRight: "18px",
                      marginTop: "6px",
                    }}
                    src={require("./googleRoundLogoNew.png")}
                  ></img>
                </a>
                <a
                  href={`https://www.facebook.com/search/places/?q=${restaurantName}`}
                  target="_blank"
                >
                  <img
                    style={{ width: "30px" }}
                    src={require("./facebookRoundLogoNew.png")}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        ` `
      </div>
      <Navbar />
      <h2 className="feedbackFormPageNumber">Step {pageNumber} of 2</h2>
      {/* <div className="feedbackFormSteps">
        <Steps
          responsive={false}
          current={pageNumber - 1}
          style={{}}
          items={[
            {
              // title: "Step 1",
            },
            {
            },
          ]}
        />
      </div> */}
      <div className="feedbackFormWholeInner">
        <div className="feedbackFormTitle">
          {/* <h4>Thank You for Supporting</h4> */}
          <h1>{restaurantName && restaurantName}</h1>
          <p className="feedbackError">
            {firstPageInstructions}
            {secondPageInstructions}
          </p>
        </div>
        <form className="feedbackFormBody">
          {/* onSubmit={(values, actions) =>{
        
      }}> */}
          {/* <div className="feedbackFormBodyInner"></div> */}
          <div className="feedbackFirstPage">
            <div className="feedbackFirstPageInner">
              <p className="feedbackRatingTitle">FOOD</p>
              <div className="starRatingDiv">
                <Ratings
                  rating={foodRating}
                  changeRating={setFoodRating}
                  // widgetRatedColors="#ffe600"
                  // widgetSpacings="5px"
                  // widgetHoverColors="#ffe600"
                  // widgetEmptyColors="white"
                  // widgetDimensions="40px"
                  widgetRatedColors="rgb(255, 213, 0)"
                  widgetSpacings="5.5px"
                  widgetHoverColors="rgb(255, 213, 0)"
                  widgetEmptyColors="rgb(241, 241, 244)"
                  widgetDimensions="40px"
                >
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                </Ratings>
              </div>
              <p className="feedbackRatingTitle">SERVICE</p>
              <div className="starRatingDiv">
                <Ratings
                  rating={serviceRating}
                  changeRating={setServiceRating}
                  // widgetRatedColors="#ffcc00"
                  // widgetSpacings="5px"
                  // widgetHoverColors="#ffcc00"
                  // widgetEmptyColors="white"
                  // widgetDimensions="40px"
                  widgetRatedColors="rgb(255, 213, 0)"
                  widgetSpacings="5.5px"
                  widgetHoverColors="rgb(255, 213, 0)"
                  widgetEmptyColors="rgb(241, 241, 244)"
                  widgetDimensions="40px"
                >
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                </Ratings>
              </div>
              <p className="feedbackRatingTitle">SETTING</p>
              <div className="starRatingDiv">
                <Ratings
                  rating={settingRating}
                  changeRating={setSettingRating}
                  widgetRatedColors="rgb(255, 213, 0)"
                  widgetSpacings="5.5px"
                  widgetHoverColors="rgb(255, 213, 0)"
                  widgetEmptyColors="rgb(241, 241, 244)"
                  widgetDimensions="40px"
                >
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                </Ratings>
              </div>
              <p className="feedbackRatingTitle">VALUE</p>
              <div className="starRatingDiv">
                <Ratings
                  rating={valueRating}
                  changeRating={setValueRating}
                  // widgetRatedColors="#white"
                  // widgetSpacings="5px"
                  // widgetHoverColors="white"
                  // widgetEmptyColors="#ffe261"
                  // widgetDimensions="40px"
                  widgetRatedColors="rgb(255, 213, 0)"
                  widgetSpacings="5.5px"
                  widgetHoverColors="rgb(255, 213, 0)"
                  widgetEmptyColors="rgb(241, 241, 244)"
                  widgetDimensions="40px"
                >
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                </Ratings>
              </div>
              <p className="feedbackRatingTitle">OVERALL *</p>
              <div className="starRatingDiv">
                <Ratings
                  rating={overallRating}
                  changeRating={setOverallRating}
                  // widgetRatedColors="#fac000"
                  widgetRatedColors="rgb(255, 213, 0)"
                  widgetSpacings="5.5px"
                  widgetHoverColors="rgb(255, 213, 0)"
                  widgetEmptyColors="rgb(241, 241, 244)"
                  widgetDimensions="40px"
                >
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                </Ratings>

                {/* <Ratings
            rating={5}
            widgetRatedColors="white"
            widgetSpacings="5px"
            widgetDimensions="45px"
          >
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
          </Ratings> */}
              </div>
              {/* <Stars /> */}
              <div className="FeedbackFormButtonField">
                {/* <button
              type="button"
              onClick={() => {
                navigate("/");
              }}
            >
              Back
            </button> */}
                <button type="button" onClick={NextOne}>
                  Next
                </button>
              </div>
            </div>
          </div>
          <div className="feedbackSecondPage">
            <div className="feedbackSecondPageElements">
              <p>Approx. Date of Visit *</p>
              {/* <Space className = "date" direction="vertical"> */}
              <DatePicker onChange={onChangeDate} placeholder={"Select Date"} />
              {/* <DatePicker onChange={onChangeDate} picker="week" />
              <DatePicker onChange={onChangeDate} picker="month" />
              <DatePicker onChange={onChangeDate} picker="quarter" />
              <DatePicker onChange={onChangeDate} picker="year" /> */}
              {/* </Space> */}

              <p>Written Feedback</p>
              <div className="feedbackSecondPageInstructions">
                <p>Briefly elaborate on your ratings.</p>
              </div>
              {/* <p>Optional:</p> */}
              <textarea
                className="writtenFeedbackTextarea"
                value={writtenNotes}
                onChange={(event) => setWrittenNotes(event.target.value)}
              ></textarea>
              {/* <p>Phone Number *</p>
              <div className="feedbackSecondPageInstructions">
                <p>The business will not receive this information</p>
              </div>

              <input
                type="tel"
                value={reviewerPhone}
                onChange={(event) => setReviewerPhone(event.target.value)}
              ></input> */}
              <div className="FeedbackFormButtonField">
                <button type="button" onClick={BackTwo}>
                  Back
                </button>
                <button
                  type="button"
                  onClick={() => {
                    pussy();
                    // bitch();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="feedbackGratitudePopup">
          <div className="feedbackGratitudePopupInner">
            <p>Thank You for Supporting</p>
            <h1>{restaurantName && restaurantName}</h1>
            {/* <p>with your feedback</p> */}
            <div>
              <img
                src={require("./starfruitFeedbackSubmitInfographic.png")}
              ></img>
            </div>
            <button
              onClick={() => {
                navigate("/");
              }}
            >
              Return to Homepage
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedbackForm;
