import React, { useState } from "react";
// import Stars from './components/Stars';
import "./HomepageForm.css";
// import {BrowserRouter as Router, Routes, Route, Link, Navigate, useParams} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./HomepageForm.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faMagnifyingGlass,
  faHouse,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";

import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
library.add(faHeart, faMagnifyingGlass, faHouse, faCircleQuestion);

const google = window.google;
// import "../src/starfruittitle.png";

function HomepageForm() {
  const [address, setAddress] = React.useState("");
  const navigate = useNavigate();

  function callback(place, status) {
    console.log(place);
  }

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    // console.log(results);
    var restaurantID = results[0].place_id;
    var restaurantName = value.substr(0, value.indexOf(","));
    // console.log(restaurantID, restaurantName);
    setAddress(restaurantName);

    // var service = new google.maps.places.PlacesService(
    //   document.getElementById("brother")
    // );
    // service.getDetails(
    //   {
    //     placeId: restaurantID,
    //   },
    //   function (place, status) {
    //     console.log("Place details:", place);
    //   }
    // );

    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ title: "React POST Request Example" }),
    // };
    // fetch(
    //   "https://maps.googleapis.com/maps/api/place/details/json?fields=name%2Crating%2Cformatted_phone_number&place_id=" +
    //     { restaurantID } +
    //     "&key=AIzaSyC4_VdLc570H5ErkRDCs-GPboVlBeYoAPk",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((data) => console.log(data));

    // const request = {
    //   place_id: restaurantID,
    //   fields: ["formatted_phone_number"],
    // };

    // const service = new google.maps.places.PlacesService(
    //   document.getElementById("brother")
    // );
    // service.getDetails(request, callback);
    // document.get
    navigate(`/feedback/id=${restaurantID}/name=${restaurantName}`, {
      state: { restaurantID, restaurantName },
    });
  };

  const searchOptions = {
    types: ["restaurant", "bakery", "bar", "cafe"],
  };
  // let {username} = useParams();
  return (
    <div className="homepageWhole">
      {/* <nav>
                <a href= '/'>Home</a>
                <a href= '/business'>Business</a>
            </nav> */}
      {/* <div> */}
      <Navbar />
      {/* <div id="brother"></div> */}
      {/* <div className="homepageBodyFirstBackground"></div> */}
      <div className="homepageBodyFirst">
        <div className="starfruitHomepageFirstBackgroundDiv">
          <img
            className="starfruitHomepageFirstBackground"
            src={require("./starfruitHomepageFirstBackground.png")}
          ></img>
        </div>
        <h1>Review any Restaurant!</h1>
        {/* <p>Find the Restaurant, Provide Feedback!</p> */}
        <div className="homepageBodyFirstText">
          {/* <p>Support Local Business with Your Feedback</p> */}
          <p style={{ marginBottom: "0px" }}>
            Constructive Feedback to Help Local Businesses Grow
          </p>
        </div>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
          searchOptions={searchOptions}
          className="placesAutocomplete"
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              {/* <input id = 'autocomplete' placeholder= "Type Address" type="text"/> */}
              {/* <button onClick = {()=> {navigate("/business")}}>go to business page</button> */}
              <FontAwesomeIcon
                id="homepageInputIcon"
                icon="fa-solid fa-magnifying-glass"
              />
              <input {...getInputProps({ placeholder: "Find Restaurants" })} />

              {/* div for all the suggestions */}
              <div className="homepageRestaurantSuggestionsDiv">
                {loading ? <div>...loading</div> : null}

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active
                      ? "rgba(256,212,36, 1)"
                      : "#fff",
                    fontSize: "15px",
                    paddingBottom: "8px",
                    paddingTop: "8px",
                    marginLeft: "-7px",
                    marginRight: "-7px",
                    marginTop: "4px",
                    marginBottom: "4px",
                  };

                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <img
          className="starfruitHomepageFirstInfographic"
          src={require("./starfruitHomepageFirstInfographic.png")}
        ></img>
      </div>
      {/* <div className="homepageBodySecondBackground"></div>
      <div className="homepageBodySecond">
        <div className="starfruitHomepageSecondBackgroundDiv">
          <img
            className="starfruitHomepageSecondBackground"
            src={require("./starfruitHomepageSecondBackground.png")}
          ></img>
        </div> */}
      {/* <h1>Feedback in 3 Easy Steps</h1>
        <div className="homepageBodySecondContent">
          <div className="homepageBodySecondSteps">
            <table>
              <tr>
                <td rowSpan={3}>
                  <img
                    className="starfruitHomepageSecondInfographic"
                    src={require("./starfruitHomepageSecondNewInfographic.png")}
                  ></img>
                </td>
                <div className="homepageBodyStepsBackground">
                  <td>
                    <h3>1</h3>
                  </td>
                  <td>
                    <p>Find a restaurant using the search bar above</p>
                  </td>
                </div>
              </tr>
              <tr>
                <div className="homepageBodyStepsBackground">
                  <td>
                    <h3>2</h3>
                  </td>
                  <td>
                    <p>
                      Give stars for overall experience, food, service, setting,
                      and value
                    </p>
                  </td>
                </div>
              </tr>
              <tr>
                <div className="homepageBodyStepsBackground">
                  <td>
                    <h3>3</h3>
                  </td>
                  <td>
                    <p>Optionally, provide written feedback and your email! </p>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        </div> */}
      {/* </div> */}

      {/* <div className="homepageBodyThirdBackground"></div>
      <div className="homepageBodyThird">
        <div className="starfruitHomepageThirdBackgroundDiv">
          <img
            className="starfruitHomepageThirdBackground"
            src={require("./starfruitHomepageThirdBackground.png")}
          ></img>
        </div>
        <h1>Why use Starfruit Instead?</h1>
        <div className="homepageBodyThirdText">
          <p>
            Leaving a review on Starfruit makes your feedback visible only
            between you and the business owner
          </p>
        </div>
        <div className="homepageBodyThirdText">
          <p>
            Private feedback in this format gives valuable information, on both
            successes and areas for improvements, without jeopardizing the
            company's presence on social platforms
          </p>
        </div>
        <img
          className="starfruitHomepageThirdInfographic"
          src={require("./starfruitHomepageThirdInfographic.png")}
        ></img>
      </div> */}
      <Footer />
    </div>
  );
}

export default HomepageForm;

// https://stackoverflow.com/questions/72004170/how-to-pass-id-in-route-react
