import React, { useEffect, useState } from "react";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth, provider, db, storage } from "./firebase-config";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import Ratings from "react-ratings-declarative";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from "react-places-autocomplete";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField } from "./TextField";

import "./BusinessVerification.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faMagnifyingGlass,
  faHouse,
  // faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
// import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

library.add(faHeart, faMagnifyingGlass, faHouse, faCircleQuestion);
// import { OptionField } from "./OptionField";

function BusinessVerification({ user }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [userVerified, setUserVerified] = useState(null);
  const [restaurantID, setRestaurantID] = useState(null);
  const [address, setAddress] = React.useState("");

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    setRestaurantID(results[0].place_id);
    var restaurantName = value.substr(0, value.indexOf(","));
    setAddress(value);
    // navigate("/feedback", { state: { restaurantID, restaurantName } });
  };

  const searchOptions = {
    types: ["restaurant", "bakery", "bar", "cafe"],
  };

  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const [useReason, setUseReason] = useState(null);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);

  const [imageRefV44, setImageRefV44] = useState(null);

  const imagesListRef = ref(storage, "businessOwnerVerificationFiles/");

  // const { register, handleSubmit, errors } = useForm({
  //   resolver: yupResolver(schema),
  // });

  // var placeLoc = place.geometry.location;
  // var marker = new google.maps.Marker({
  //   map: map,
  //   position: place.geometry.location,
  // });

  // var request = { reference: place.reference };
  // service.getDetails(request, function (details, status) {
  //   google.maps.event.addListener(marker, "click", function () {
  //     infowindow.setContent(
  //       details.name +
  //         "<br />" +
  //         details.formatted_address +
  //         "<br />" +
  //         details.website +
  //         "<br />" +
  //         details.rating +
  //         "<br />" +
  //         details.formatted_phone_number
  //     );
  //     infowindow.open(map, this);
  //   });
  // });

  const schema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    reason: Yup.string().required("Reason is required"),
  });

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  // const submitForm = (data) => {
  //   console.log(data);
  // };

  useEffect(() => {
    // alert("user in database");
    const updateBusinessOwnerDatabase = async () => {
      //restaurant in database, set restaurant, add review
      if (userVerified) {
        // alert(imageRefV44);
        // alert(address);
        // alert(userVerified[0].id);
        const docData = {
          business_account_date_verification_submitted: Timestamp.now(),
          business_account_first_name: firstName,
          business_account_last_name: lastName,
          business_account_use_reason: useReason,
          business_account_verification_status: "under review",
          business_ownership_file_ID: imageRefV44,
          restaurant_google_places_ID: restaurantID,
        };
        updateDoc(doc(db, "business owners", userVerified[0].id), docData).then(
          () => {
            window.location.reload(false);
          }
        );
      }
    };
    updateBusinessOwnerDatabase();
  }, [userVerified && firstName && lastName && imageRefV44 && address]);

  return (
    <div className="businessVerification">
      <h3>Welcome! Please fill out our</h3>
      <div className="navbarDashboardColorBackground">
        <nav>
          <img src={require("./starfruittitle.png")}></img>
        </nav>
      </div>
      <h1>Restaurant Verification Form</h1>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstName) {
            errors.firstName = "First Name Required";
          }
          if (!values.lastName) {
            errors.lastName = "Last Name Required";
          }
          if (!address) {
            errors.address = "Restaurant Required";
          }
          if (!useReason) {
            errors.reason = "Reason Required";
          }
          if (!imageUpload) {
            errors.verificationFile = "File Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          // alert("hi");
          if (imageUpload == null) return;
          const imageRefV4 = v4();
          setImageRefV44(imageRefV4);
          const imageRef = ref(
            storage,
            `businessOwnerVerificationFiles/${imageRefV4}`
          );
          uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
              setImageUrls((prev) => [...prev, url]);
            });
          });
          const getUserVerified = async () => {
            // alert(user.uid);
            const qqqqq = query(
              collection(db, "business owners"),
              where("userUID", "==", user.uid)
            );
            const data = await getDocs(qqqqq);
            setUserVerified(
              data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            );
            setFirstName(values.firstName);
            setLastName(values.lastName);
            if (data.empty) {
              // alert("user not in database");
              const docRef = await addDoc(collection(db, "business owners"), {
                business_account_date_created: Timestamp.now(),
                business_account_date_verification_submitted: Timestamp.now(),
                business_account_first_name: values.firstName,
                business_account_last_name: values.lastName,
                business_account_use_reason: useReason,
                business_account_verification_status: "under review",
                business_ownership_file_ID: imageRefV4,
                restaurant_google_places_ID: address,
              });
            }
          };
          getUserVerified();
          // setSubmitting(false);
          // window.location.reload(false);
          // }, 400);
        }}

        // validationSchema={schema}
        // onSubmit={(values) => {
        //   console.log(values);
        // }}
        // onSubmit={}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div className="businessVerificationForm">
            <form onSubmit={handleSubmit}>
              <p>FIRST NAME</p>
              <input
                placeholder="Enter First Name"
                name="firstName"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
              />
              <p className="errorLabel">
                {errors.firstName && touched.firstName && errors.firstName}
              </p>
              {/* <ErrorMessage name="firstName" /> */}
              <p>LAST NAME</p>
              <input
                placeholder="Enter Last Name"
                name="lastName"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
              />
              <p className="errorLabel">
                {errors.lastName && touched.lastName && errors.lastName}
              </p>
              {/* <ErrorMessage name="lastName" /> */}
              <p>YOUR BUSINESS</p>
              <PlacesAutocomplete
                name="address"
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
                searchOptions={searchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      name="restaurant"
                      {...getInputProps({
                        placeholder: "Enter Restaurant Name",
                      })}
                    />
                    {/* div for all the suggestions */}
                    <div className="verificationFormRestaurantSuggestionsOuter">
                      {loading ? <div>...loading</div> : null}

                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "gold"
                            : "rgb(245, 245, 245)",
                        };

                        return (
                          <div
                            className="verificationFormRestaurantSuggestions"
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <p className="errorLabel">{errors.address}</p>
              <div className="proofOfQwnershipTitle">
                <a href="businessverificationproofofownership" target="_blank">
                  Proof of Ownership
                </a>
                <a href="businessverificationproofofownership" target="_blank">
                  <FontAwesomeIcon
                    id="businessverificationproofofownershipIcon"
                    href="businessverificationproofofownership"
                    target="_blank"
                    icon="fa-regular fa-circle-question"
                  />
                </a>
              </div>
              {/* <a href="businessverificationproofofownership" target="_blank">
                Acceptable Verification Types
              </a> */}

              {/* <div className="fileUploadDiv">
                <label for="file-upload" class="custom-file-upload">
                  Choose File
                </label>
              </div> */}

              <input
                id="file-upload"
                type="file"
                size="70"
                name="verificationFile"
                onSelect={handleSelect}
                searchOptions={searchOptions}
                onChange={(event) => {
                  setImageUpload(event.target.files[0]);
                }}
              ></input>

              <p className="errorLabelFile">{errors.verificationFile}</p>
              <p className="">Why ?</p>
              <select
                name="reason"
                onChange={(e) => setUseReason(e.target.value)}
                onBlur={handleBlur}
                onSelect={handleSelect}
                value={useReason}

                // value={values.lastName}
              >
                <option disabled selected value>
                  CHOOSE AN OPTION
                </option>
                <option value="review customer feedback">
                  Review Customer Feedback
                </option>
                <option value="communicate with customers">
                  Communicate with Customers
                </option>
                <option value="analyze employee service">
                  Analyze Employee Service
                </option>
              </select>
              {/* <ErrorMessage name="reason" /> */}
              <p className="errorLabelSelect">{errors.reason}</p>
              <div className="businessVerificationFormButtonField">
                <button className="btn btn-dark mt-3" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );

  // return (
  //   <div>
  //     <form onSubmit={handleSubmit(submitForm)}>
  //       <p>First Name</p>
  //       <input
  //         type="text"
  //         name="firstName"
  //         {...register("firstName", { required: "First Name required" })}
  //       ></input>
  //       {/* <p>{errors.firstName?.message}</p> */}
  //       {/* <p>{...errors.firstName?.message}</p> */}
  //       {/* {errors.firstName?.message && <p>{errors.firstName?.message}</p>} */}
  //       <p>Last Name</p>
  //       <input
  //         type="text"
  //         name="lastName"
  //         {...register("lastName", { required: "Last Name required" })}
  //       ></input>
  //       {/* {errors.lastName?.message && <p>{errors.lastName?.message}</p>} */}
  //       <p>Enter your Restaurant</p>
  //       <PlacesAutocomplete
  //         value={address}
  //         onChange={setAddress}
  //         onSelect={handleSelect}
  //         searchOptions={searchOptions}
  //       >
  //         {({
  //           getInputProps,
  //           suggestions,
  //           getSuggestionItemProps,
  //           loading,
  //         }) => (
  //           <div>
  //             <input {...getInputProps({ placeholder: "Type Address" })} />
  //             {/* div for all the suggestions */}
  //             <div>
  //               {loading ? <div>...loading</div> : null}

  //               {suggestions.map((suggestion) => {
  //                 const style = {
  //                   backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
  //                 };

  //                 return (
  //                   <div {...getSuggestionItemProps(suggestion, { style })}>
  //                     {suggestion.description}
  //                   </div>
  //                 );
  //               })}
  //             </div>
  //           </div>
  //         )}
  //       </PlacesAutocomplete>
  //       <p>Enter your Proof of Ownership</p>
  //       <input
  //         type="file"
  //         name="verificationFile"
  //         onChange={(event) => {
  //           setImageUpload(event.target.files[0]);
  //         }}
  //       ></input>
  //       <p>Why are you looking to use Starfruit?</p>
  //       <select name="reason" {...register("reason")}>
  //         <option disabled selected value>
  //           Choose an Option
  //         </option>
  //         <option value="review customer feedback">
  //           Review Customer Feedback
  //         </option>
  //         <option value="communicate with customers">
  //           Communicate with Customers
  //         </option>
  //         <option value="analyze employee service">
  //           Analyze Employee Service
  //         </option>
  //         {/* <option
  //         value="other"
  //         onselect={(document.getElementById(bitch).style = "inline")}
  //       >
  //         Other
  //       </option> */}
  //       </select>
  //       {/* <p> {errors.reason?.message} </p> */}
  //       {/* <input type="text" id="bitch" /> */}

  //       {/* <button onClick={uploadImage}>Submit</button> */}
  //       {/* <button>Submit</button> */}
  //       <input type="submit" id="submit" />
  //     </form>
  //     {/* {imageUrls.map((url) => {
  //       return <img src={url} />;
  //     })} */}
  //   </div>
  // );
}

export default BusinessVerification;

// function BusinessVerification() {
//   const [userVerified, setUserVerified] = useState(null);
//   const [address, setAddress] = React.useState("");

//   const [user, setUser] = useState(null);

//   onAuthStateChanged(auth, (currentUser) => {
//     setUser(currentUser);
//   });

//   const handleSelect = async (value) => {
//     const results = await geocodeByAddress(value);
//     var restaurantID = results[0].place_id;
//     var restaurantName = value.substr(0, value.indexOf(","));
//     setAddress(value);
//   };

//   const searchOptions = {
//     types: ["restaurant", "bakery", "bar", "cafe"],
//   };

//   const [imageUpload, setImageUpload] = useState(null);
//   const [imageUrls, setImageUrls] = useState([]);

//   const imagesListRef = ref(storage, "businessOwnerVerificationFiles/");

//   const uploadImage = () => {
//     if (imageUpload == null) return;
//     const imageRef = ref(
//       storage,
//       `businessOwnerVerificationFiles/${imageUpload.name + v4()}`
//     );
//     uploadBytes(imageRef, imageUpload).then((snapshot) => {
//       getDownloadURL(snapshot.ref).then((url) => {
//         setImageUrls((prev) => [...prev, url]);
//       });
//     });
//   };

//   // const updateUser = () => {
//   //   if (user) {
//   //     const qqqq = query(
//   //       collection(db, "business owners"),
//   //       where("userUID", "==", user.uid)
//   //     );
//   //     const getUserVerified = async () => {
//   //       const data = await getDocs(qqqq);
//   //       setUserVerified(
//   //         data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
//   //       );

//   //       if (data.empty) {
//   //       } else {
//   //         alert("user in database");
//   //       }
//   //     };
//   //     getUserVerified();
//   //   }
//   // };

//   // useEffect(() => {
//   //   const getRestaurantData = async () => {
//   //     if (isAuth) {
//   //       // alert("csndjck");
//   //     }
//   //   };

//   //   getRestaurantData();
//   // }, []);

//   // useEffect(() => {
//   //   listAll(imagesListRef).then((response) => {
//   //     response.items.forEach((item) => {
//   //       getDownloadURL(item).then((url) => {
//   //         setImageUrls((prev) => [...prev, url]);
//   //       });
//   //     });
//   //   });
//   // }, []);

//   //verified user
//   // useEffect(() => {}, [user]);

//   return (
//     <div>
//       <form>
//         <p>First Name</p>
//         <input type="text"></input>
//         <p>Last Name</p>
//         <input type="text"></input>
//         <p>Enter your Restaurant</p>
//         <PlacesAutocomplete
//           value={address}
//           onChange={setAddress}
//           onSelect={handleSelect}
//           searchOptions={searchOptions}
//         >
//           {({
//             getInputProps,
//             suggestions,
//             getSuggestionItemProps,
//             loading,
//           }) => (
//             <div>
//               <input {...getInputProps({ placeholder: "Type Address" })} />
//               {/* div for all the suggestions */}
//               <div>
//                 {loading ? <div>...loading</div> : null}

//                 {suggestions.map((suggestion) => {
//                   const style = {
//                     backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
//                   };

//                   return (
//                     <div {...getSuggestionItemProps(suggestion, { style })}>
//                       {suggestion.description}
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           )}
//         </PlacesAutocomplete>
//         <p>Enter your Proof of Ownership</p>
//         <input
//           type="file"
//           onChange={(event) => {
//             setImageUpload(event.target.files[0]);
//           }}
//         ></input>
//         <p>Why are you looking to use Starfruit?</p>
//         <select name="cars">
//           <option disabled selected value>
//             Choose an Option
//           </option>
//           <option value="review customer feedback">
//             Review Customer Feedback
//           </option>
//           <option value="communicate with customers">
//             Communicate with Customers
//           </option>
//           <option value="analyze employee service">
//             Analyze Employee Service
//           </option>
//           {/* <option
//           value="other"
//           onselect={(document.getElementById(bitch).style = "inline")}
//         >
//           Other
//         </option> */}
//         </select>
//         {/* <input type="text" id="bitch" /> */}
//         <button onClick={uploadImage}>Submit</button>
//       </form>
//       {/* {imageUrls.map((url) => {
//         return <img src={url} />;
//       })} */}
//     </div>
//   );
// }
