import React, { useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { auth, provider, db } from "./firebase-config";
import { getDocs, collection, where, query, doc } from "firebase/firestore";
import Ratings from "react-ratings-declarative";
import Stars from "./Stars";
import "./BusinessAnalytics.css";
import NavbarDashboard from "./NavbarDashboard";
// import { scaleLinear } from "d3-scale";
import BusinessAnalyticsChart from "./BusinessAnalyticsChart";
import Footer from "./Footer.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faCircleDown,
  faArrowAltCircleDown,
} from "@fortawesome/free-regular-svg-icons";
import {
  faMagnifyingGlass,
  faChartColumn,
  faChartSimple,
  faUser,
  faArrowDown,
  faArrow,
} from "@fortawesome/free-solid-svg-icons";
import { InputNumber, Button, Input } from "antd";
library.add(
  faChartBar,
  faUser,
  faChartColumn,
  faChartSimple,
  faArrowDown,
  faArrowAltCircleDown,
  faCircleDown
);

// function BusinessAnalytics({ user }) {
function BusinessAnalytics({ isAuth, setIsAuth }) {
  // const restaurantID = "ChIJpSV9Ey98hYARoiJOCEki8Vc";
  const [userVerified, setUserVerified] = useState(null);
  const [userUID, setUserUID] = useState(null);

  const [businessAccountData, setBusinessAccountData] = useState(null);
  const [reviewsData, setReviewsData] = useState(null);
  const [restaurantData, setRestaurantData] = useState(null);

  const [restaurant_overall_avg, setRestaurant_overall_avg] = useState(0);
  const [restaurant_food_avg, setRestaurant_food_avg] = useState(0);
  const [restaurant_service_avg, setRestaurant_service_avg] = useState(0);
  const [restaurant_setting_avg, setRestaurant_setting_avg] = useState(0);
  const [restaurant_value_avg, setRestaurant_value_avg] = useState(0);

  const [individualReviewSearchInput, setIndividualReviewSearchInput] =
    useState("");

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      window.location.pathname = "/business";
    });
  };

  //reviews list

  // useEffect(() => {
  //   setUserUID(
  //     window.location.href.substring(window.location.href.indexOf("id=") + 3)
  //   );
  // }, []);

  useEffect(() => {
    if (businessAccountData) {
      // console.log(businessAccountData[0]);
      const q = query(
        collection(db, "reviews"),
        where(
          "restaurant_google_places_ID",
          "==",
          businessAccountData[0].restaurant_google_places_ID
        )
      );
      const qq = query(
        collection(db, "restaurants"),
        where(
          "restaurant_google_places_ID",
          "==",
          businessAccountData[0].restaurant_google_places_ID
        )
      );
      const getReviews = async () => {
        const data = await getDocs(q);
        setReviewsData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      };
      const getRestaurantData = async () => {
        const data = await getDocs(qq);
        setRestaurantData(
          data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      };
      getReviews();
      getRestaurantData();
    }
  }, [businessAccountData]);

  //user data list
  useEffect(() => {
    setUserUID(
      window.location.href.substring(window.location.href.indexOf("id=") + 3)
    );
    const qqq = query(
      collection(db, "business owners"),
      // where("userUID", "==", user.uid)
      where(
        "userUID",
        "==",
        window.location.href.substring(window.location.href.indexOf("id=") + 3)
      )
    );
    const getUsers = async () => {
      const data = await getDocs(qqq);
      setBusinessAccountData(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    getUsers();
  }, []);

  useEffect(() => {
    if (restaurantData) {
      {
        restaurantData[0].restaurant_total_reviews > 0
          ? setRestaurant_overall_avg(
              Math.round(
                (restaurantData[0].restaurant_overall_pts /
                  restaurantData[0].restaurant_total_reviews) *
                  10
              ) / 10
            )
          : setRestaurant_overall_avg(0);
      }

      {
        restaurantData[0].restaurant_total_food_reviews > 0
          ? setRestaurant_food_avg(
              Math.round(
                (restaurantData[0].restaurant_food_pts /
                  restaurantData[0].restaurant_total_food_reviews) *
                  10
              ) / 10
            )
          : setRestaurant_food_avg(0);
      }

      {
        restaurantData[0].restaurant_total_service_reviews > 0
          ? setRestaurant_service_avg(
              Math.round(
                (restaurantData[0].restaurant_service_pts /
                  restaurantData[0].restaurant_total_service_reviews) *
                  10
              ) / 10
            )
          : setRestaurant_service_avg(0);
      }

      {
        restaurantData[0].restaurant_total_setting_reviews > 0
          ? setRestaurant_setting_avg(
              Math.round(
                (restaurantData[0].restaurant_setting_pts /
                  restaurantData[0].restaurant_total_setting_reviews) *
                  10
              ) / 10
            )
          : setRestaurant_setting_avg(0);
      }

      {
        restaurantData[0].restaurant_total_value_reviews > 0
          ? setRestaurant_value_avg(
              Math.round(
                (restaurantData[0].restaurant_value_pts /
                  restaurantData[0].restaurant_total_value_reviews) *
                  10
              ) / 10
            )
          : setRestaurant_value_avg(0);
      }
    }
  }, [restaurantData]);

  // if (!(restaurantData && reviewsData)) {
  //   alert("hi");
  //   return null;
  // }
  // const [style, setStyle] = useState("cont");

  // const makeIndividualReviewStatsPopup = () => {
  //   setStyle("visible");
  // };

  const [clickedIndex, setClickedIndex] = useState();

  // const signUserOut = () => {
  //   signOut(auth).then(() => {
  //     localStorage.clear();
  //     setIsAuth(false);
  //     window.location.pathname = "/business";
  //   });
  // };

  return (
    <div className="analyticsWhole">
      <NavbarDashboard />
      <button className="signOutButton" onClick={signUserOut}>
        Logout
      </button>
      <div className="analyticsWholeInner">
        <div className="analyticsNav">
          <div className="iconDiv">
            <FontAwesomeIcon className="icon" icon="fa-solid fa-chart-simple" />
          </div>
          <div className="iconDiv">
            <FontAwesomeIcon className="icon" icon="fa-solid fa-user" />
          </div>
        </div>
        <div className="analyticsBody">
          <div className="analyticsTitle">
            {/* <h3>Welcome,</h3> */}
            {restaurantData && <h1>{restaurantData[0].restaurant_name}</h1>}
          </div>
          <div className="analyticsBodyInner">
            <h2>Summary</h2>
            <p
              style={{
                fontSize: "14px",
                paddingBottom: "10px",
                color: "rgb(180, 180, 180)",
                lineHeight: "20px",
                marginBottom: "3px",
              }}
            >
              Average ratings are between 1 and 5. An average of 0 means you
              don't have any ratings yet.
            </p>
            <div className="summaryDiv" style={{ marginBottom: "30px" }}>
              <div className="summaryDivFirstRow">
                <div>
                  <div className="summaryDivFirstRowInner">
                    <p>OVERALL: </p>
                    <h3>{restaurant_overall_avg}</h3>
                    <div style={{ marginLeft: "0px", marginTop: "4px" }}>
                      {/* {restaurant_overall_avg && ( */}
                      <Ratings
                        rating={restaurant_overall_avg}
                        widgetRatedColors="rgb(255, 213, 0)"
                        widgetSpacings="2.2px"
                        widgetHoverColors="#fbc70c"
                        widgetDimensions="24.2px"
                        widgetEmptyColors="rgb(241, 241, 244)"
                      >
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget />
                      </Ratings>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="summaryDivSecondRow">
                <div
                  className="summaryDivSecondRowItem"
                  style={{ marginRight: "9px" }}
                >
                  <p>FOOD: </p>
                  <h3>{restaurant_food_avg}</h3>
                </div>
                <div
                  className="summaryDivSecondRowItem"
                  style={{ marginRight: "9px" }}
                >
                  <p>SERVICE:</p>
                  <h3>{restaurant_service_avg}</h3>
                </div>
                <div
                  className="summaryDivSecondRowItem"
                  style={{ marginRight: "9px" }}
                >
                  <p>SETTING:</p>
                  <h3>{restaurant_setting_avg}</h3>
                </div>
                <div className="summaryDivSecondRowItem">
                  <p>VALUE:</p>
                  <h3>{restaurant_value_avg}</h3>
                </div>
              </div>
            </div>
            <h2>Graph</h2>
            <BusinessAnalyticsChart />
            <div className="individualReviews">
              <h2>Individual Reviews</h2>
              <p
                style={{
                  fontSize: "14px",
                  paddingBottom: "10px",
                  color: "rgb(180, 180, 180)",
                  lineHeight: "20px",
                  // marginBottom: "3px",
                }}
              >
                <button
                  style={{
                    textDecoration: "none",
                    // color: "rgb(255, 213, 0)",
                    border: "1.5px solid rgb(236, 236, 236)",
                    // color: "white",
                    // backgroundColor: "rgb(255, 213, 0)",
                    border: "none",
                    height: "28px",
                    padding: "4.5px",
                    width: "100px",
                    marginTop: "2px",
                    marginBottom: "5px",
                    // marginLeft: "8px",
                    marginRight: "3px",

                    borderRadius: "3px",
                    // fontWeight: "600",
                  }}
                  onClick={() =>
                    window.open("http://localhost:3000/qrcode", "_blank")
                  }
                >
                  Get QR Code
                </button>{" "}
                for customers to scan and submit feedback:
              </p>
              {/* <div className="filterReviews">
                <button>Temperature</button>
                <button>Taste</button>
                <button>Price</button>
                <button>Setting</button>
              </div> */}
              <Input
                onChange={(e) => setIndividualReviewSearchInput(e.target.value)}
                placeholder="Search Through Written Reviews"
                prefix={
                  <FontAwesomeIcon
                    id="businessAnalyticsInputIcon"
                    icon="fa-solid fa-magnifying-glass"
                  />
                }
              />
              {/* <FontAwesomeIcon
                id="businessAnalyticsInputIcon"
                icon="fa-solid fa-magnifying-glass"
              /> */}
              <div className="individualReviewsDiv">
                {reviewsData &&
                  reviewsData.map((review, index) => {
                    // const [hoe, setHoe] = useState(null);
                    // individualReviewSearchInput === "" &&
                    // if(individualReviewSearchInput === ""){

                    // }
                    // individualReviewSearchInput? :
                    if (
                      individualReviewSearchInput === "" ||
                      review.review_written_notes.includes(
                        individualReviewSearchInput
                      )
                    ) {
                      return (
                        <div key={index} className="individualReviewDiv">
                          <div className="individualReviewDateDiv">
                            <img
                              src={require("./starfruitSingleLogo.png")}
                              style={{ width: "25px" }}
                            />
                            <p className="reviewDate">
                              {/* Visited:{" "} */}
                              {review.review_date_submitted
                                .toDate()
                                .toString()
                                .substr(4, 11)}
                            </p>
                            {/* <p>
                          Posted:{" "}
                          {review.review_date_submitted
                            .toDate()
                            .toString()
                            .substr(4, 11)}
                        </p> */}
                          </div>
                          {/* <div>
                        {clickedIndex == index && (
                          <div className="individualReviewStatsPopup">
                            <table>
                              <tr>
                                <td>
                                  <p className="ratingTitle">OVERALL: </p>
                                </td>
                                <td>
                                  <div className="individualReviewRatingNumber">
                                    <p className="individualReviewRatingNumber">
                                      {review.review_overall_rating}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p className="ratingTitle">FOOD: </p>
                                </td>
                                <td>
                                  {" "}
                                  <div className="individualReviewRatingNumber">
                                    <p className="individualReviewRatingNumber">
                                      {review.review_food_rating}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p className="ratingTitle">SERVICE: </p>
                                </td>
                                <td>
                                  {" "}
                                  <div className="individualReviewRatingNumber">
                                    <p className="individualReviewRatingNumber">
                                      {review.review_service_rating}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p className="ratingTitle">SETTING: </p>
                                </td>
                                <td>
                                  <div className="individualReviewRatingNumber">
                                    <p className="individualReviewRatingNumber">
                                      {review.review_setting_rating}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p className="ratingTitle">VALUE: </p>
                                </td>
                                <td>
                                  <div className="individualReviewRatingNumber">
                                    <p>{review.review_value_rating}</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        )}
                      </div> */}
                          <div className="individualReviewRatingDiv">
                            <div
                              className="summaryDiv"
                              style={{ paddingTop: "3px" }}
                            >
                              {/* <table> */}
                              <div>
                                <p style={{ marginRight: "8px" }}>OVERALL: </p>
                                {restaurant_overall_avg && (
                                  <Ratings
                                    rating={review.review_overall_rating}
                                    widgetRatedColors="rgb(255, 213, 0)"
                                    widgetSpacings="2px"
                                    widgetHoverColors="#fbc70c"
                                    widgetDimensions="22px"
                                    widgetEmptyColors="rgb(241, 241, 244)"
                                  >
                                    <Ratings.Widget />
                                    <Ratings.Widget />
                                    <Ratings.Widget />
                                    <Ratings.Widget />
                                    <Ratings.Widget />
                                  </Ratings>
                                )}
                                <button
                                  className="individualReviewMoreLargeButton"
                                  onClick={() => {
                                    clickedIndex == index
                                      ? setClickedIndex(null)
                                      : setClickedIndex(index);
                                  }}
                                >
                                  More Stats
                                </button>
                                <button
                                  className="individualReviewMoreSmallButton"
                                  onClick={() => {
                                    clickedIndex == index
                                      ? setClickedIndex(null)
                                      : setClickedIndex(index);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="icon"
                                    icon="fa-solid fa-arrow-down"
                                  />
                                </button>
                              </div>
                              {/* <tr>
                              <td className="ratingTitle">
                                <p style={{ marginLeft: "0px" }}>OVERALL: </p>
                              </td>
                              <td>
                                {restaurant_overall_avg && (
                                  <Ratings
                                    rating={review.review_overall_rating}
                                    widgetRatedColors="rgb(255, 200, 0)"
                                    widgetSpacings="2px"
                                    widgetHoverColors="#fbc70c"
                                    widgetDimensions="22px"
                                    widgetEmptyColors="#fbc70c"
                                  >
                                    <Ratings.Widget />
                                    <Ratings.Widget />
                                    <Ratings.Widget />
                                    <Ratings.Widget />
                                    <Ratings.Widget />
                                  </Ratings>
                                )}
                                <button
                                  onClick={() => {
                                    clickedIndex == index
                                      ? setClickedIndex(null)
                                      : setClickedIndex(index);
                                  }}
                                >
                                  More Stats
                                </button>
                              </td>
                              <td className="ratingNumber"></td>
                            </tr> */}
                              {clickedIndex == index && (
                                <div className="individualReviewRatingDivDiv">
                                  <div
                                    className="individualReviewRatingDivItem"
                                    style={{ marginRight: "9px" }}
                                  >
                                    <p>FOOD: </p>
                                    <h3>{review.review_food_rating}</h3>
                                  </div>
                                  <div
                                    className="individualReviewRatingDivItem"
                                    style={{ marginRight: "9px" }}
                                  >
                                    <p>SERVICE:</p>
                                    <h3>{review.review_service_rating}</h3>
                                  </div>
                                  <div
                                    className="individualReviewRatingDivItem"
                                    style={{ marginRight: "9px" }}
                                  >
                                    <p>SETTING:</p>
                                    <h3>{review.review_setting_rating}</h3>
                                  </div>
                                  <div className="individualReviewRatingDivItem">
                                    <p>VALUE:</p>
                                    <h3>{review.review_value_rating}</h3>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {review.review_written_notes ? (
                            <>
                              <div className="individualReviewNotesDiv">
                                {review.review_written_notes}
                              </div>{" "}
                            </>
                          ) : (
                            <></>
                          )}

                          <div className="individualReviewContactDiv">
                            <p>{review.review_submitter_email}</p>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BusinessAnalytics;
