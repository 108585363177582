import logo from "./logo.svg";
import React, { useState } from "react";
import "./App.css";
import HomepageForm from "./HomepageForm";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import Business from "./Business";
import FeedbackForm from "./FeedbackForm";
import ErrorPage from "./ErrorPage";
import PrivacyPolicy from "./PrivacyPolicy";
import BusinessDashboard from "./BusinessDashboard";
import BusinessVerification from "./BusinessVerification";
import BusinessVerificationNew from "./BusinessVerificationNew";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "./firebase-config";
import BusinessAnalytics from "./BusinessAnalytics";
import BusinessVerificationProofofOwnership from "./BusinessVerificationProofofOwnership";
import QRCodeGenerator from "./QRCodeGenerator";

function App() {
  const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));

  // const [user, setUser] = useState({});

  // onAuthStateChanged(auth, (currentUser) => {
  //   setUser(currentUser);
  // });

  // let navigate = useNavigate()

  // const signUserOut = () => {
  //   signOut(auth).then(() => {
  //     localStorage.clear();
  //     setIsAuth(false);
  //     window.location.pathname = "/business";
  //     // navigate('/business');
  //   });
  // };

  return (
    <div className="App">
      <Router>
        {/* <Route path = "/feedback" element = {<FeedbackForm />}></Route> */}

        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<HomepageForm />} />
          <Route
            path="/qrcode"
            // element={<QRCode isAuth={isAuth} setIsAuth={setIsAuth} />}
            element={<QRCodeGenerator />}
          />
          <Route
            path="/business"
            element={<Business isAuth={isAuth} setIsAuth={setIsAuth} />}
          />
          {/* <Route path="/feedback" element={<FeedbackForm />} /> */}
          <Route
            path="/feedback/id=:id/name=:name"
            element={<FeedbackForm />}
          />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route
            path="/businessverificationproofofownership"
            element={<BusinessVerificationProofofOwnership />}
          />
          <Route
            path="/businessdashboard"
            element={
              <BusinessDashboard isAuth={isAuth} setIsAuth={setIsAuth} />
            }
          />
          <Route element={<BusinessVerification />} />
          <Route
            path="/businessverification/id=:id"
            element={
              <BusinessVerificationNew isAuth={isAuth} setIsAuth={setIsAuth} />
            }
          />
          <Route
            path="/businessanalytics/id=:id"
            element={
              <BusinessAnalytics isAuth={isAuth} setIsAuth={setIsAuth} />
            }
          />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
      {/* <Router> */}
      {/* <SignupForm /> */}
      {/* <Homepage /> */}
      {/* <FeedbackForm /> */}
      {/* <HomepageForm /> */}
      {/* </Router> */}
    </div>
  );
}

export default App;
