import React, { useEffect, useState } from "react";
// import { scaleLinear } from "d3-scale";
// import { LineChart } from "d3-scale";
import Plot from "react-plotly.js";
// import type { DatePickerProps } from 'antd';
import { DatePicker, Select } from "antd";
import { Checkbox, Col, Row } from "antd";
import "./BusinessAnalyticsChart.css";

// import * as d3 from "https://cdn.skypack.dev/d3@7";
// import { LineChart } from "@d3/line-chart";

function BusinessAnalyticsChart() {
  const { Option } = Select;
  const [type, setType] = useState("Year");
  const [overallGraphChecked, setOverallGraphChecked] = useState(true);
  const [foodGraphChecked, setFoodGraphChecked] = useState(false);
  const [serviceGraphChecked, setServiceGraphChecked] = useState(false);
  const [settingGraphChecked, setSettingGraphChecked] = useState(false);
  const [valueGraphChecked, setValueGraphChecked] = useState(false);
  const [chartYear, setChartYear] = useState(2022);

  const xAxis = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const yAxis = [
    [4.3, 4.4, 4.5, 4.1, 4.0, 4.2, 4.5, 4.3, 4.6, 4.5, 4.7, 4.6],
    [4.0, 4.4, 4.1, 4.2, 4.4, 4.1, 4.2, 3.9, 4.3, 4.1, 4.2, 4.3],
    [4.5, 4.2, 4.0, 4.6, 4.7, 4.3, 4.0, 3.2, 4.3, 4.4, 4.7, 4.6],
    [4.2, 4.5, 4.3, 4.0, 4.6, 4.3, 4.0, 3.7, 4.5, 4.3, 4.0, 4.4],
    [4.3, 4.5, 4.0, 4.4, 4.6, 4.3, 4.0, 3.8, 4.1, 4.4, 4.0, 4.5],
  ];

  var layout = {
    // title: "Multiple Trace Filled-Area Animation",
    height: 210,
    // width: "100%",

    margin: {
      l: 25,
      r: 0,
      b: 35,
      t: 20,
      pad: 4,
    },
    // width: 550,
    xaxis: {
      value: xAxis,
    },
    yaxis: {
      range: [1, 5],
    },
    showlegend: false,
    // backgroundColor: "blue",
  };

  const onChange = (date, dateString) => {
    console.log(dateString);
    setChartYear(dateString);
  };

  const onChangeCheckbox = (varr, funcc) => {
    if (varr) {
      funcc(false);
    } else {
      funcc(true);
    }
  };

  var config = { responsive: true };

  return (
    <div className="businessAnalyticsChart">
      <p
        style={{
          color: "rgb(180, 180, 180)",
          lineHeight: "20px",
          marginBottom: "3px",
        }}
        className="inProgress"
      >
        Feature in progress, graph below only a demo.
      </p>
      {/* <div className="businessAnalyticsChartHide"></div> */}
      {/* <Select value={type} onChange={setType}>
        <Option value="year">Year</Option>
        <Option value="month">Month</Option>
      </Select> */}
      <DatePicker
        className="datePickerBusinessAnalytics"
        onChange={onChange}
        picker="year"
        // defaultValue={"2015-01-01"}
      />
      <div
        style={{
          zIndex: "100",
          marginTop: "15px",
          marginBottom: "-8px",
        }}
      >
        <div
          style={{
            width: "100px",
            // minWidth: "95px",
            display: "inline-block",
            // maxWidth: "110px",
            height: "30px",
          }}
        >
          <Checkbox
            defaultChecked={overallGraphChecked}
            onChange={() =>
              onChangeCheckbox(overallGraphChecked, setOverallGraphChecked)
            }
            value="A"
            style={{
              marginRight: "8px",
              marginBottom: "7px",
              // color: "#ac843c",\
              color: "rgb(180, 180, 180)",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            OVERALL
          </Checkbox>
        </div>
        <div
          style={{
            width: "80px",
            display: "inline-block",
            height: "30px",
          }}
        >
          <Checkbox
            checked={foodGraphChecked}
            onChange={() =>
              onChangeCheckbox(foodGraphChecked, setFoodGraphChecked)
            }
            value="B"
            style={{
              marginBottom: "7px",
              marginRight: "8px",
              color: "rgb(180, 180, 180)",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            FOOD
          </Checkbox>
        </div>
        <div
          style={{
            width: "95px",
            display: "inline-block",
            height: "30px",
          }}
        >
          <Checkbox
            checked={serviceGraphChecked}
            onChange={() =>
              onChangeCheckbox(serviceGraphChecked, setServiceGraphChecked)
            }
            value="C"
            style={{
              marginBottom: "7px",
              marginRight: "8px",
              color: "rgb(180, 180, 180)",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            SERVICE
          </Checkbox>
        </div>
        <div
          style={{
            width: "98px",
            display: "inline-block",
            height: "30px",
          }}
        >
          <Checkbox
            checked={settingGraphChecked}
            onChange={() =>
              onChangeCheckbox(settingGraphChecked, setSettingGraphChecked)
            }
            value="D"
            style={{
              marginBottom: "7px",
              marginRight: "8px",
              color: "rgb(180, 180, 180)",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            SETTING
          </Checkbox>
        </div>
        <div
          style={{
            width: "85px",
            display: "inline-block",
            height: "30px",
          }}
        >
          <Checkbox
            checked={valueGraphChecked}
            onChange={() =>
              onChangeCheckbox(valueGraphChecked, setValueGraphChecked)
            }
            value="E"
            style={{
              marginBottom: "7px",
              color: "rgb(180, 180, 180)",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            VALUE
          </Checkbox>
        </div>
      </div>
      <div className="plot">
        <div className="plotGraph">
          <Plot
            style={{ width: "100%" }}
            // className="plot"
            data={[
              overallGraphChecked
                ? {
                    x: xAxis,
                    y: yAxis[0],
                    // fill: "tozeroy",
                    line: { shape: "spline", smoothing: 0.7 },
                    marker: { color: "rgb(255, 213, 0)" },
                  }
                : {},
              foodGraphChecked
                ? {
                    x: xAxis,
                    y: yAxis[1],
                    // fill: "tozeroy",
                    line: { shape: "spline", smoothing: 0.7 },
                    marker: { color: "rgb(255, 230, 0)" },
                  }
                : {},
              serviceGraphChecked
                ? {
                    x: xAxis,
                    y: yAxis[2],
                    // fill: "tozeroy",
                    line: { shape: "spline", smoothing: 0.7 },
                    marker: { color: "rgb(255, 191, 0)" },
                  }
                : {},
              settingGraphChecked
                ? {
                    x: xAxis,
                    y: yAxis[3],
                    // fill: "tozeroy",
                    line: { shape: "spline", smoothing: 0.7 },
                    marker: { color: "rgb(255, 242, 0)" },
                  }
                : {},
              valueGraphChecked
                ? {
                    x: xAxis,
                    y: yAxis[4],
                    // fill: "tozeroy",
                    line: { shape: "spline", smoothing: 0.7 },
                    marker: { color: "rgb(255, 179, 0)" },
                  }
                : {},
              { x: xAxis, y: [, , , , , , , , , , , ,] },
            ]}
            layout={layout}
            config={config}
          />
        </div>

        {/* <div
          style={{
            width: "15%",
            display: "inline-block",
            minWidth: "110px",
            borderRadius: "5px",
            zIndex: "100",
            paddingTop: "10px",
            paddingBottom: "3px",
            border: "1px solid rgb(240, 240, 240)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Checkbox
            defaultChecked={overallGraphChecked}
            onChange={() =>
              onChangeCheckbox(overallGraphChecked, setOverallGraphChecked)
            }
            value="A"
            style={{
              width: "100%",
              marginLeft: "8px",
              marginBottom: "7px",
              color: "rgb(180, 180, 180)",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            OVERALL
          </Checkbox>
          <Checkbox
            checked={foodGraphChecked}
            onChange={() =>
              onChangeCheckbox(foodGraphChecked, setFoodGraphChecked)
            }
            value="B"
            style={{
              width: "100%",
              marginBottom: "7px",
              color: "rgb(180, 180, 180)",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            FOOD
          </Checkbox>
          <Checkbox
            checked={serviceGraphChecked}
            onChange={() =>
              onChangeCheckbox(serviceGraphChecked, setServiceGraphChecked)
            }
            value="C"
            style={{
              width: "100%",
              marginBottom: "7px",
              color: "rgb(180, 180, 180)",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            SERVICE
          </Checkbox>
          <Checkbox
            checked={settingGraphChecked}
            onChange={() =>
              onChangeCheckbox(settingGraphChecked, setSettingGraphChecked)
            }
            value="D"
            style={{
              width: "100%",
              marginBottom: "7px",
              color: "rgb(180, 180, 180)",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            SETTING
          </Checkbox>
          <Checkbox
            checked={valueGraphChecked}
            onChange={() =>
              onChangeCheckbox(valueGraphChecked, setValueGraphChecked)
            }
            value="E"
            style={{
              width: "100%",
              marginBottom: "7px",
              color: "rgb(180, 180, 180)",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            VALUE
          </Checkbox>
        </div> */}
      </div>
    </div>
  );
}

export default BusinessAnalyticsChart;
