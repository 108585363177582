import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "./firebase-config";
import "./NavbarDashboard.css";

function NavbarDashboard() {
  const navigate = useNavigate();
  return (
    <div className="navbarBodyDash">
      <nav>
        {/* <p>Starfruit</p> */}
        <img
          onClick={() => navigate("/")}
          src={require("./starfruittitle.png")}
        ></img>
        {/* <button onClick={signUserOut}> Log Out</button> */}

        {/* <div className="navbarLinks">
          <Link className="navbarLink" to="/">
            Home
          </Link>
          <Link className="navbarLink" to="/business">
            Business
          </Link>
        </div> */}
        {/* {!isAuth ? (
    <Link to="/businessdashboard"> Login </Link>
  ) : (
    <>
      <button onClick={signUserOut}> Log Out</button>
    </>
  )} */}
      </nav>
    </div>
  );
}

export default NavbarDashboard;
